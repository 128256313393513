import { Tooltip } from "@mui/material";
import Toggler from "../../../components/Toggler";
import FormInput from "../../../components/form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import {
  faXmark,
  faHeadset,
  faPowerOff,
  faPhoneSlash,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";

const GeneralSettingsPageView = ({ form, configs, handleChange, handleToggle, handleSubmit }) => {
  return (
    <>
      <div className='title'>
        <span>General Settings</span>
      </div>
      <div className='settings-content'>
        <div className='row'>
          <div className='content'>
            <div className='title'>
              <FontAwesomeIcon icon={faPowerOff} className='max-w-5' />
              <span>Service Online</span>
            </div>
            <div className='description items-center'>
              <span>
                Toggle to set the service status.
                <br /> Switch on to mark the service as online and available or switch off to mark
                it as offline and unavailable.
              </span>
              <Toggler
                toggle={configs.isOnline}
                handleToggle={(toggle) => handleToggle("isOnline", toggle)}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='content'>
            <div className='title'>
              <FontAwesomeIcon icon={faPhoneSlash} className='max-w-5' />
              <span>Phone Support</span>
            </div>
            <div className='description items-center'>
              <span>
                Toggle to enable or disable phone support.
                <br />
                Switch on to make phone support available and switch off to mark it as unavailable.
              </span>
              <Toggler
                toggle={configs.isPhoneSupport}
                handleToggle={(toggle) => handleToggle("isPhoneSupport", toggle)}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='content'>
            <div className='title'>
              <FontAwesomeIcon icon={faFileLines} className='max-w-5' />
              <span>New Terms</span>
            </div>
            <div className='description items-center'>
              <span>
                Toggle to enable or disable new terms.
                <br />
                Switch on to mark new terms as TRUE and switch off to mark them as FALSE.
              </span>
              <Toggler
                toggle={configs.newTerms}
                handleToggle={(toggle) => handleToggle("newTerms", toggle)}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='content'>
            <div className='title'>
              <FontAwesomeIcon icon={faHeadset} />
              <span>Support Phone Number</span>
            </div>
            <div className='description flex-col items-end sm:flex-row sm:items-center'>
              <span>
                Edit to change the company's support phone number.
                <br /> This will be displayed to customers for contact purposes.
              </span>
              <div className='flex items-center gap-2'>
                <FormInput
                  type='text'
                  value={form.values.supportPhone}
                  placeholder='Support phone number'
                  handleChange={(e) => handleChange("supportPhone", e)}
                  errors={form.touched.supportPhone && form.errors.supportPhone}
                />
                {form.initialValues.supportPhone !== form.values.supportPhone && (
                  <div className='flex items-center gap-2'>
                    <Tooltip title='Save changes' placement='top'>
                      <FontAwesomeIcon
                        size='xl'
                        icon={faFloppyDisk}
                        className='text-text-main cursor-pointer'
                        onClick={() => handleSubmit("supportPhone")}
                      />
                    </Tooltip>
                    <Tooltip title='Discard changes' placement='top'>
                      <FontAwesomeIcon
                        size='xl'
                        icon={faXmark}
                        className='text-text-main cursor-pointer'
                        onClick={() =>
                          form.setFieldValue("supportPhone", form.initialValues.supportPhone)
                        }
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralSettingsPageView;
