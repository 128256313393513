import { createTheme } from "@mui/material";

export const MuiTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: "#fff",
          fontSize: "12px",
          backgroundColor: "#222222F2",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "rgba(var(--text-main))",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          transition: "all 0.1s ease-in-out, background-color 0.1s ease-in-out",
          fieldset: {
            transition: "all 0.1s ease-in-out, background-color 0.1s ease-in-out",
            borderColor: "rgba(var(--border-input))",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(var(--bg-input))",
          "&:hover fieldset": {
            borderColor: "rgba(var(--border-input-hover)) !important",
          },
          "&.Mui-disabled": {
            fieldset: {
              borderColor: "rgba(var(--border-input)) !important",
            },
            "&:hover fieldset": {
              borderColor: "rgba(var(--border-input-hover)) !important",
            },
            button: {
              color: "rgba(var(--text-main), .5) !important",
            },
            cursor: "not-allowed",
            color: "rgba(var(--text-main), .5) !important",
            input: {
              cursor: "not-allowed",
              color: "rgba(var(--text-main), .5) !important",
              "-webkit-text-fill-color": "unset",
            },
          },
          input: {
            border: "none",
            padding: "6px !important",
            color: "rgba(var(--text-main), 1) !important",
            transition: "all 0.1s ease-in-out, background-color 0.1s ease-in-out",
            "&::placeholder": {
              color: "rgba(var(--text-main), 1) !important",
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "4px !important",
          "&.Mui-expanded": {
            margin: "0px",
          },
          "&.MuiPaper-root": {
            backgroundColor: "rgba(var(--bg-main))",
            transition: "color 0.1s ease-in-out, background-color 0.1s ease-in-out",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0px",
          minHeight: "unset",
          ".MuiAccordionSummary-content": {
            margin: "0px 0px 10px 0px !important",
            fontSize: "16px",
            fontWeight: "bold",
            color: "rgba(var(--text-main))",
            transition: "color 0.1s ease-in-out, background-color 0.1s ease-in-out",
          },
          "&.Mui-expanded": {
            minHeight: "unset",
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          padding: "10px 10px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingLeft: "8px !important",
          color: "rgba(var(--text-main))",
        },
        head: {
          borderBottom: "1px solid rgba(var(--border-table-header))",
        },
        body: {
          borderBottom: "1px solid rgba(var(--border-table-body))",
        },
      },
    },
  },
});
