import "./styles/Footer.scss";
import { faTrademark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faRegistered } from "@fortawesome/free-regular-svg-icons";

const Footer = () => {
  return (
    <div className='footer'>
      <div className='page-wrapper'>
        <div className='page-content'>
          <div className='wrapper'>
            <div className='copyrights'>
              <span>All rights reserved.</span>
              <FontAwesomeIcon icon={faRegistered} className='ml-1' />
              <FontAwesomeIcon icon={faCopyright} className='ml-1' />
              <FontAwesomeIcon icon={faTrademark} className='ml-1' />
              <span className='ml-1'>Caroo Mobility Services P.C.</span>
            </div>
            <div className='version'>
              <span>v2.18.12</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
