import Parse from "parse";
import Loader from "../../Loader";
import { useEffect, useState } from "react";
import Logger from "../../../models/Logger";
import useUser from "../../../hooks/useUser";
import useToaster from "../../../hooks/useToaster";
import HappyHourCardView from "../view/HappyHourCardView";

const HappyHourCardState = ({ happyHour }) => {
  const userHook = useUser();
  const toaster = useToaster();
  const session = userHook.getCurrentSession();
  const [isLoading, setLoading] = useState(false);
  const [happyHourState, setHappyHourState] = useState(null);

  const handleToggle = async (value) => {
    setLoading(true);
    try {
      const HappyHour = new Parse.Object("HappyHour").set("objectId", happyHour.id);
      HappyHour.set("isEnabled", value);
      await HappyHour.save(null, { useMasterKey: true });
      await Logger.toggleHappyHour(session, HappyHour);
      setHappyHourState(HappyHour);
      setLoading(false);
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleSubmit = async (form) => {
    setLoading(true);

    try {
      const HappyHour = new Parse.Object("HappyHour").set("objectId", happyHour.id);
      HappyHour.set("text", form.values.text);
      HappyHour.set("type", form.values.type);
      HappyHour.set("endsAt", form.values.endsAt);
      HappyHour.set("startsAt", form.values.startsAt);
      HappyHour.set("discount", Number(form.values.discount));
      await HappyHour.save(null, { useMasterKey: true });
      await Logger.editHappyHour(session, HappyHour);
      setHappyHourState(HappyHour);
      setLoading(false);
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  useEffect(() => {
    setHappyHourState(happyHour);
  }, [happyHour]);

  return (
    <div className='happy-hour-wrapper'>
      {!!!happyHourState ? (
        <Loader isLoading={isLoading} />
      ) : (
        <HappyHourCardView
          isLoading={isLoading}
          happyHour={happyHourState}
          handleToggle={handleToggle}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default HappyHourCardState;
