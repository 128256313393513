import Parse from "parse";
import "../styles/styles.scss";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useEffect, useState } from "react";
import Logger from "../../../models/Logger";
import useUser from "../../../hooks/useUser";
import Loader from "../../../components/Loader";
import useToaster from "../../../hooks/useToaster";
import GeneralSettingsPageView from "../view/GeneralSettingsPageView";

const GeneralSettingsPageState = ({}) => {
  const userHook = useUser();
  const toaster = useToaster();
  const [configs, setConfigs] = useState(null);
  const session = userHook.getCurrentSession();
  const [isLoading, setLoading] = useState(false);
  const [supportPhone, setSupportPhone] = useState("");
  const validationSchema = object().shape({
    supportPhone: string().required("Support phone is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      supportPhone: supportPhone,
    },
  });

  const getConfigs = async () => {
    setLoading(true);
    try {
      Parse.Config.get().then((config) => {
        setConfigs(config.attributes);
        setSupportPhone(config.attributes.supportPhone);
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleToggle = async (config, value) => {
    try {
      if (
        window.confirm(
          `Are you sure you want to switch the ${config} value to ${value ? "TRUE" : "FALSE"} ?`
        )
      ) {
        setLoading(true);
        Parse.Config.save({ [config]: value }).then(async (r) => {
          await Logger.editGeneralSettings(session, config + " " + (value ? "TRUE" : "FALSE"));
          setConfigs(r.attributes);
          formik.setFieldValue("supportPhone", r.attributes.supportPhone);
          setLoading(false);
        });
      }
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleChange = async (config, e) => {
    try {
      formik.setFieldTouched(config);
      formik.setFieldValue(config, e.target.value);
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleSubmit = async (config) => {
    try {
      if (window.confirm(`Are you sure you want to apply the changes on ${config}?`)) {
        setLoading(true);
        Parse.Config.save({ [config]: formik.values[config] }).then(async (r) => {
          await Logger.editGeneralSettings(
            session,
            "updated " + config + " with value '" + formik.values[config] + "'"
          );
          setConfigs(r.attributes);
          setSupportPhone(r.attributes.supportPhone);
          setLoading(false);
        });
      }
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  useEffect(() => {
    getConfigs();
  }, []);

  return (
    <>
      {!!configs ? (
        <>
          <Loader isLoading={isLoading} />
          <GeneralSettingsPageView
            form={formik}
            configs={configs}
            isLoading={isLoading}
            handleChange={handleChange}
            handleToggle={handleToggle}
            handleSubmit={handleSubmit}
          />
        </>
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default GeneralSettingsPageState;
