import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Utils from "../../../utils/Utils";
import { differenceInYears } from "../../../utils/Dates";
import HtmlTooltip from "../../../components/HtmlTooltip";
import FormInput from "../../../components/form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import FormDatepicker from "../../../components/form/FormDatepicker";
import { faMinus, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const UserDetails = ({
  user,
  form,
  isEditing,
  isLoadingForm,
  handleFormChange,
  duplicateLicenseNo,
}) => {
  const age = user.dateOfBirth && differenceInYears(user.dateOfBirth);
  const licenseAge = user.license.issue && differenceInYears(user.license.issue);

  return (
    <>
      <div className='mb-2'>
        <span className='text-lg font-bold'>User Details</span>
      </div>
      <div className='user-details'>
        <span>Email:</span>
        <span>
          {user.username}{" "}
          {user.emailVerified && (
            <Tooltip title='Email is verified' placement='top'>
              <FontAwesomeIcon icon={faCircleCheck} className='text-green-600' />
            </Tooltip>
          )}
        </span>
        <span>First Name:</span>
        <span>{Utils.textFirstOnlyUpper(user.firstName)}</span>
        <span>Last Name:</span>
        <span>{Utils.textFirstOnlyUpper(user.lastName)}</span>
        <span>Phone:</span>
        <span>
          {user.phone}{" "}
          {user.phoneVerified && (
            <Tooltip title='Phone is verified' placement='top'>
              <FontAwesomeIcon icon={faCircleCheck} className='text-green-600' />
            </Tooltip>
          )}
        </span>
        <span>Gender:</span>
        <span>{Utils.textFirstOnlyUpper(user.gender)}</span>
        <span>Date of Birth:</span>
        <span>
          {user.dateOfBirth ? (
            <>
              {user.dateOfBirth} -{" "}
              <Tooltip
                title={`User is ${age} years old and is ${
                  age >= 21 ? "eligible" : "not eligible"
                } for authorization.`}
                placement='top'
              >
                <span
                  className={`${
                    age >= 21 ? "bg-green-400" : "bg-red-400"
                  } bg-opacity-30 p-1 rounded ml-1 cursor-pointer`}
                >
                  {age}
                </span>
              </Tooltip>
            </>
          ) : (
            <FontAwesomeIcon icon={faMinus} />
          )}
        </span>
        <span>Driving License ID:</span>
        {isEditing ? (
          <div>
            <FormInput
              type='text'
              disabled={isLoadingForm}
              placeholder='License number'
              value={form.values.licenseId}
              errors={form.touched.licenseId && form.errors.licenseId}
              handleChange={(e) => handleFormChange("licenseId", e.target.value)}
            />
          </div>
        ) : (
          <span>
            {user.license.id ?? <FontAwesomeIcon icon={faMinus} />}
            {!!duplicateLicenseNo && (
              <HtmlTooltip
                title={
                  <>
                    <div className='mb-1'>
                      <span>This user's License No has been found in another user too.</span>
                    </div>
                    <div>
                      <span>
                        User{" "}
                        <Link className='text-blue-400' to={"/users/" + duplicateLicenseNo.id}>
                          {duplicateLicenseNo.username}
                        </Link>{" "}
                        shares the same License No.
                      </span>
                    </div>
                  </>
                }
              >
                <FontAwesomeIcon
                  size='lg'
                  icon={faTriangleExclamation}
                  className='ml-1 text-red-500'
                />
              </HtmlTooltip>
            )}
          </span>
        )}
        <span>License Issue Date:</span>
        {isEditing ? (
          <div>
            <FormDatepicker
              withTime={false}
              isClearable={false}
              value={dayjs(form.values.licenseIssueDate)}
              handleChange={(value) => handleFormChange("licenseIssueDate", value)}
              errors={form.touched.licenseIssueDate && form.errors.licenseIssueDate}
            />
          </div>
        ) : (
          <span>
            {user.license.issue ? (
              <>
                {user.license.issue} -{" "}
                <Tooltip
                  title={`License is ${licenseAge} years old and is ${
                    licenseAge >= 1 ? "eligible" : "not eligible"
                  } for authorization.`}
                  placement='top'
                >
                  <span
                    className={`${
                      licenseAge >= 1 ? "bg-green-400" : "bg-red-400"
                    } bg-opacity-30 p-1 rounded ml-1 cursor-pointer`}
                  >
                    {licenseAge}
                  </span>
                </Tooltip>
              </>
            ) : (
              <FontAwesomeIcon icon={faMinus} />
            )}
          </span>
        )}
        <span>License Expiry Date:</span>
        {isEditing ? (
          <div>
            <FormDatepicker
              withTime={false}
              isClearable={false}
              value={dayjs(form.values.licenseExpiryDate)}
              handleChange={(value) => handleFormChange("licenseExpiryDate", value)}
              errors={form.touched.licenseExpiryDate && form.errors.licenseExpiryDate}
            />
          </div>
        ) : (
          <span>{user.license.expire ?? <FontAwesomeIcon icon={faMinus} />}</span>
        )}
      </div>
    </>
  );
};

export default UserDetails;
