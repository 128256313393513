import dayjs from "dayjs";
import { Tooltip } from "@mui/material";
import Utils from "../../../utils/Utils";
import { metersToKM } from "../../../utils/Units";
import { secondsToHoursMinutes } from "../../../utils/Dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDatepicker from "../../../components/form/FormDatepicker";
import { faEuro, faMinus, faPercentage, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const RentingDetails = ({
  renting,
  totalBill,
  form = null,
  isEditing = null,
  handleFormChange = null,
}) => {
  return (
    <>
      <div className='mb-2'>
        <span className='text-lg font-bold'>Renting Details</span>
      </div>
      <div className='renting-details'>
        <span>Cost</span>
        <span>
          {!!totalBill.bill ? (
            <>
              <FontAwesomeIcon icon={faEuro} /> {parseFloat(totalBill.bill).toFixed(2)}
              {isEditing && (
                <Tooltip
                  title="The bill will be calculated automatically upon changing the renting's start and end dates."
                  placement='top'
                >
                  <FontAwesomeIcon
                    className='absolute text-blue-500 cursor-pointer ml-1'
                    icon={faQuestionCircle}
                    size='2xs'
                  />
                </Tooltip>
              )}
            </>
          ) : (
            <div>
              <FontAwesomeIcon icon={faMinus} />
            </div>
          )}
        </span>
        <span>Discount</span>
        <span>
          {!!totalBill.discount ? (
            <>
              {totalBill.discount.type === "FIX" ? (
                <div className='flex items-center gap-1'>
                  <FontAwesomeIcon icon={faEuro} />
                  <font>{parseFloat(totalBill.discount.amount).toFixed(2)}</font>
                </div>
              ) : (
                <div className='flex items-center gap-1'>
                  <font>{totalBill.discount.amount}</font>
                  <FontAwesomeIcon icon={faPercentage} />(
                  {parseFloat(totalBill.discount.applies).toFixed(2)}
                  <FontAwesomeIcon icon={faEuro} />)
                </div>
              )}
              {isEditing && (
                <Tooltip
                  title="The bill will be calculated automatically upon changing the renting's start and end dates."
                  placement='top'
                >
                  <FontAwesomeIcon
                    className='absolute text-blue-500 cursor-pointer ml-1'
                    icon={faQuestionCircle}
                    size='2xs'
                  />
                </Tooltip>
              )}
            </>
          ) : (
            <div>
              <FontAwesomeIcon icon={faMinus} />
            </div>
          )}
        </span>
        <span>Total Cost</span>
        <span>
          {!Utils.isNull(totalBill.totalBill) ? (
            <>
              <FontAwesomeIcon icon={faEuro} /> {parseFloat(totalBill.totalBill).toFixed(2)}
              {isEditing && (
                <Tooltip
                  title="The bill will be calculated automatically upon changing the renting's start and end dates."
                  placement='top'
                >
                  <FontAwesomeIcon
                    className='absolute text-blue-500 cursor-pointer ml-1'
                    icon={faQuestionCircle}
                    size='2xs'
                  />
                </Tooltip>
              )}
            </>
          ) : (
            <div>
              <FontAwesomeIcon icon={faMinus} />
            </div>
          )}
        </span>
        <span>Time</span>

        {Utils.isNull(renting.time.totalTime) ? (
          <div>
            <FontAwesomeIcon icon={faMinus} />
          </div>
        ) : (
          <span>
            {secondsToHoursMinutes(renting.time.totalTime)}'
            {isEditing && (
              <Tooltip
                title="The total time will be calculated automatically upon changing the renting's start and end dates."
                placement='top'
              >
                <FontAwesomeIcon
                  className='absolute text-blue-500 cursor-pointer ml-1'
                  icon={faQuestionCircle}
                  size='2xs'
                />
              </Tooltip>
            )}
          </span>
        )}
        <span>Distance</span>
        {Utils.isNull(renting.distance) ? (
          <div>
            <FontAwesomeIcon icon={faMinus} />
          </div>
        ) : (
          <span>
            {metersToKM(renting.distance)} km
            {isEditing && (
              <Tooltip
                title="The distance will be calculated automatically upon changing the renting's start and end geolocations."
                placement='top'
              >
                <FontAwesomeIcon
                  className='absolute text-blue-500 cursor-pointer ml-1'
                  icon={faQuestionCircle}
                  size='2xs'
                />
              </Tooltip>
            )}
          </span>
        )}
        <span>Started on</span>
        {isEditing ? (
          <div className='flex items-center w-full'>
            <FormDatepicker
              withTime
              isClearable={false}
              errors={form.touched.startTime && form.errors.startTime}
              value={dayjs(new Date(form.values.startTime).toISOString())}
              handleChange={(value) => handleFormChange("startTime", value)}
            />
          </div>
        ) : (
          <span>{renting.time.startTime}</span>
        )}
        <span>Ended on</span>
        {isEditing ? (
          <div className='flex items-center w-full'>
            <FormDatepicker
              withTime
              isClearable={false}
              errors={form.touched.endTime && form.errors.endTime}
              value={
                !!form.values.endTime
                  ? dayjs(new Date(form.values.endTime).toISOString())
                  : dayjs(new Date())
              }
              handleChange={(value) => handleFormChange("endTime", value)}
            />
          </div>
        ) : (
          <>
            {Utils.isNull(renting.time.endTime) ? (
              <FontAwesomeIcon icon={faMinus} />
            ) : (
              <span>{renting.time.endTime}</span>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RentingDetails;
