import { useState } from "react";
import ImageLightbox from "../../../components/ImageLightbox";
import DocumentViewer from "../../../components/DocumentViewer";

const Selfie = ({ picture }) => {
  const slides = [{ src: picture }];
  const [index, setIndex] = useState(-1);

  return (
    <div>
      <div className='mb-2'>
        <span className='text-lg font-bold'>Selfie</span>
      </div>
      <div className='rounded-lg aspect-square'>
        <DocumentViewer document={picture} position="center" height="unset" />
      </div>
      <ImageLightbox index={index} slides={slides} setIndex={setIndex} />
    </div>
  );
};

export default Selfie;
