import Parse from "parse";
import dayjs from "dayjs";
import { useFormik } from "formik";
import Utils from "../../../utils/Utils";
import Status from "../components/Status";
import { date, object, string } from "yup";
import { useEffect, useState } from "react";
import CarNotes from "../components/CarNotes";
import { useNavigate } from "react-router-dom";
import CarStatus from "../components/CarStatus";
import { findOption } from "../../../utils/Forms";
import CarDetails from "../components/CarDetails";
import MapTracker from "../components/MapTracker";
import PageMenu from "../../../components/PageMenu";
import Button from "../../../components/form/Button";
import CarAmenities from "../components/CarAmenities";
import CarInversStatus from "../components/CarInversStatus";
import FormSelect from "../../../components/form/FormSelect";
import CarAdditionalInfo from "../components/CarAdditionalInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import DamagesPageState from "../../damages/state/DamagesPageState";
import FormDatepicker from "../../../components/form/FormDatepicker";
import RentingsPageState from "../../rentings/state/RentingsPageState";
import MaintenancesPageState from "../../maintenance/state/MaintenancesPageState";
import {
  faLock,
  faEuro,
  faMinus,
  faLockOpen,
  faCircleNotch,
  faArrowsRotate,
  faArrowRightToBracket,
} from "@fortawesome/free-solid-svg-icons";

const CarPageView = ({
  car,
  jobs,
  totals,
  carStatus,
  isLoading,
  handleLock,
  modemStatus,
  selectedJobs,
  isLoadingModem,
  setSelectedJobs,
  isLoadingTotals,
  isLoadingInvers,
  handleNotesSubmit,
  handleToggleRiding,
  handleRefreshStatus,
  getInversModemStatus,
  handleToggleAvailable,
  handleToggleBluetooth,
  handleToggleChildSeat,
  handleTogglePetFriendly,
  switchInversModemNetwork,
  handleToggleUnderMaintenance,
  // ------ Maintenance logic ------ //
  activeMaintenance,
  refreshMaintenances,
  isLoadingMaintenance,
  handleStartMaintenance,
  handleCloseMaintenance,
  handleChangeMaintainer,
  // ------ Maintenance logic ------ //
}) => {
  const navigate = useNavigate();
  const [openTab, setTab] = useState(0);
  const [isInfoOpen, setInfoOpen] = useState(false);
  const [maintainers, setMaintainers] = useState([]);
  const [openMaintenance, setOpenMaintenance] = useState(false);

  const validationSchema = object().shape({
    maintainer: string().required("Maintainer cannot be empty"),
    maintenanceDate: date()
      .required("Maintenance date is required")
      .min(dayjs().startOf("day"), "End time cannot be earlier than today"),
  });

  const formik = useFormik({
    maintainer: !!activeMaintenance ? activeMaintenance?.attributes?.user?.id : null,
    initialValues: {
      maintenanceDate: dayjs().startOf("day"),
    },
    validationSchema: validationSchema,
  });

  const defaultMaintainer = !Utils.isNull(formik.values.maintainer)
    ? findOption(maintainers, formik.values.maintainer)
    : null;

  const getMaintainers = async () => {
    const role = await new Parse.Query(Parse.Role)
      .equalTo("name", "Maintainer")
      .first({ useMasterKey: true });
    const maintainers = await role.relation("users").query().find({ useMasterKey: true });

    setMaintainers(
      maintainers.map((m) => ({
        value: m.id,
        label: m.attributes.name + " " + m.attributes.lastName,
      }))
    );
  };

  const handleFormChange = (field, value) => {
    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  };

  const handleMarkerClick = () => {
    setInfoOpen(true);
  };

  const handleCancel = async () => {
    formik.setFieldValue("maintainer", null);
    formik.setFieldValue("maintenanceDate", dayjs().startOf("day"));
    setOpenMaintenance(false);
    setSelectedJobs([]);
  };

  const handleStart = () => {
    formik.setFieldTouched("maintainer");
    formik.setFieldTouched("maintenanceDate");

    if (!!formik.values.maintainer && !!formik.values.maintenanceDate) {
      handleStartMaintenance(
        formik.values.maintainer,
        dayjs(formik.values.maintenanceDate).toDate()
      );
    }
  };

  const handleClose = async () => {
    handleCloseMaintenance().then(() => {
      formik.setFieldValue("maintainer", null);
      formik.setFieldValue("maintenanceDate", dayjs().startOf("day"));
      setOpenMaintenance(false);
      setSelectedJobs([]);
    });
  };

  const setJob = (jobId) => {
    if (selectedJobs.includes(jobId)) {
      setSelectedJobs((prev) => {
        return prev.filter((i) => i !== jobId);
      });
    } else {
      setSelectedJobs((prev) => [...prev, jobId]);
    }
  };

  useEffect(() => {
    getMaintainers();
  }, []);

  return (
    <div className='page-wrapper relative'>
      <div className='page-content'>
        <div className='page-title'>
          <div className='flex flex-col sm:flex-row gap-0 sm:gap-4 items-start sm:items-center'>
            <div className='flex items-center gap-1 sm:gap-4'>
              <img className='w-36' src={car.photo} />
              <div className='truncate'>
                <div className='title'>{car.carPlate}</div>
                <div className='title'>
                  {car.brand} {car.model}
                </div>
              </div>
            </div>
            <div className='flex flex-row sm:flex-col items-center sm:items-start'>
              <CarInversStatus
                car={car}
                modemStatus={modemStatus}
                isLoadingModem={isLoadingModem}
                getInversModemStatus={getInversModemStatus}
                switchInversModemNetwork={switchInversModemNetwork}
              />
              <Status car={car} />
            </div>
          </div>
          <div className='flex items-center gap-2'>
            <div
              className='flex items-center justify-center w-8 h-8 bg-white border border-solid border-gray-200 rounded cursor-pointer'
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faArrowRightToBracket} className='rotate-180' size='lg' />
            </div>
            <PageMenu>
              <div
                className='flex items-center cursor-pointer'
                onClick={() => navigate(`/cars/${car.id}/docs`)}
              >
                <FontAwesomeIcon icon={faFileLines} />
                <span className='ml-2 text-sm'>Car Documents</span>
              </div>
              <div
                className={`flex items-center ${
                  isLoading ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={() => (!isLoading ? handleRefreshStatus() : null)}
              >
                <FontAwesomeIcon
                  icon={faArrowsRotate}
                  className={`${isLoading && "animate-spin"}`}
                />
                <span className='ml-2 text-sm'>{isLoading ? "Refreshing" : "Refresh"}</span>
              </div>
              <div
                className={`flex items-center ${
                  isLoading ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={!isLoading ? handleLock : null}
              >
                <FontAwesomeIcon
                  icon={carStatus?.invers?.central_lock === "locked" ? faLockOpen : faLock}
                />
                <span className='ml-2 text-sm'>
                  {carStatus?.invers?.central_lock === "locked" ? "Unlock" : "Lock"}
                </span>
              </div>
            </PageMenu>
          </div>
        </div>
        <div className='page-totals'>
          <div>
            <span>Rentings</span>
            <span>
              {isLoadingTotals ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className='animate-spin mt-4 opacity-50'
                  size='2xs'
                />
              ) : !!totals.rentings ? (
                totals.rentings
              ) : (
                <FontAwesomeIcon icon={faMinus} />
              )}
            </span>
          </div>
          <div>
            <span>Points</span>
            <span>1000</span>
          </div>
          <div>
            <span>Revenue</span>
            <span>
              {isLoadingTotals ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className='animate-spin mt-4 opacity-50'
                  size='2xs'
                />
              ) : !!totals.revenue ? (
                <>
                  {parseFloat(totals.revenue).toFixed(2)}
                  <FontAwesomeIcon icon={faEuro} className='ml-1' />
                </>
              ) : (
                <FontAwesomeIcon icon={faMinus} />
              )}
            </span>
          </div>
          <div>
            <span>Total OPEX</span>
            <span>
              <FontAwesomeIcon icon={faMinus} />
            </span>
          </div>
        </div>
        <div className='tabs grid-cols-4'>
          <div onClick={() => setTab(0)} className={`tab ${openTab === 0 ? "active" : "inactive"}`}>
            <span>Car Info</span>
          </div>
          <div onClick={() => setTab(1)} className={`tab ${openTab === 1 ? "active" : "inactive"}`}>
            <span>Recent Rentings</span>
          </div>
          <div onClick={() => setTab(2)} className={`tab ${openTab === 2 ? "active" : "inactive"}`}>
            <span>Maintenances</span>
          </div>
          <div onClick={() => setTab(3)} className={`tab ${openTab === 3 ? "active" : "inactive"}`}>
            <span>Damages</span>
          </div>
        </div>
        <div className='py-4'>
          {openTab === 0 ? (
            <>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4'>
                <div className='box-border-rounded with-bg'>
                  <CarDetails car={car} carStatus={carStatus} isLoadingInvers={isLoadingInvers} />
                </div>
                <div className='box-border-rounded with-bg'>
                  <CarStatus
                    car={car}
                    carStatus={carStatus}
                    isLoadingInvers={isLoadingInvers}
                    handleToggleRiding={handleToggleRiding}
                    isLoading={isLoading || isLoadingInvers}
                    handleToggleAvailable={handleToggleAvailable}
                    handleToggleUnderMaintenance={handleToggleUnderMaintenance}
                  />
                </div>
                <div className='box-border-rounded with-bg'>
                  <CarAdditionalInfo car={car} />
                </div>
                <div className='box-border-rounded with-bg'>
                  <CarNotes car={car} handleNotesSubmit={handleNotesSubmit} />
                </div>
                <div className='box-border-rounded with-bg'>
                  <CarAmenities
                    car={car}
                    isLoading={isLoading}
                    handleToggleBluetooth={handleToggleBluetooth}
                    handleToggleChildSeat={handleToggleChildSeat}
                    handleTogglePetFriendly={handleTogglePetFriendly}
                  />
                </div>
                <div className='h-80 box-border-rounded with-bg relative'>
                  <MapTracker
                    cars={[car]}
                    singleCar={true}
                    isInfoOpen={isInfoOpen}
                    handleMarkerClick={handleMarkerClick}
                  />
                </div>
              </div>
            </>
          ) : openTab === 1 ? (
            <RentingsPageState carId={car.id} />
          ) : openTab === 2 ? (
            <div>
              {!!activeMaintenance ? (
                <div className='bg-bg-card-main border border-border-card-main rounded-xl'>
                  <div className='px-5 py-2 text-center'>
                    <span className='text-xl text-text-main'>
                      Βρέθηκε ενεργή συντήρηση για αυτό το όχημα
                    </span>
                  </div>
                  <div className='grid grid-cols-1 md:grid-cols-2 items-end md:grid-cols-2 p-4'>
                    <div className='flex items-end gap-2'>
                      <FormSelect
                        options={maintainers}
                        label='Change maintainer'
                        value={defaultMaintainer}
                        handleChange={(value) => handleFormChange("maintainer", value.value)}
                      />
                      <Button
                        isLoading={isLoadingMaintenance}
                        handleClick={() => handleChangeMaintainer(formik.values.maintainer)}
                        disabled={
                          isLoadingMaintenance ||
                          formik.values.maintainer === activeMaintenance.attributes.user.id
                        }
                        classes={`${
                          formik.values.maintainer === activeMaintenance.attributes.user.id
                            ? "bg-bg-btn-cancel"
                            : "bg-bg-btn-primary"
                        } p-2 text-white ${isLoadingMaintenance && "pr-8 flex justify-center"}`}
                      >
                        Assign
                      </Button>
                    </div>
                    <div>
                      <div className='flex justify-end'>
                        <Button
                          handleClick={handleClose}
                          disabled={isLoadingMaintenance}
                          isLoading={isLoadingMaintenance}
                          classes={`bg-bg-btn-cancel p-2 text-white ${
                            isLoadingMaintenance && "pr-8"
                          }`}
                        >
                          Close Maintenance
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`${
                    openMaintenance &&
                    "bg-bg-card-main border border-border-card-main p-2 rounded-xl"
                  }`}
                >
                  {openMaintenance && (
                    <div className='mb-4 sm:mb-2'>
                      <div className='text-lg font-medium text-center mb-4 sm:mb-2'>
                        <span className='text-text-main'>
                          Plan a new maintenance for {car.carPlate}
                        </span>
                      </div>
                      <div className='grid grid-cols-1 items-start sm:grid-cols-2 gap-1 sm:gap-2'>
                        <div>
                          <FormSelect
                            options={maintainers}
                            label='Select a maintainer to assign the maintenance'
                            errors={formik.touched.maintainer && formik.errors.maintainer}
                            handleChange={(value) => handleFormChange("maintainer", value.value)}
                            value={
                              !Utils.isNull(formik.values.maintainer)
                                ? findOption(maintainers, formik.values.maintainer)
                                : null
                            }
                          />
                        </div>
                        <div>
                          <FormDatepicker
                            withTime={false}
                            isClearable={true}
                            disablePastDates={true}
                            value={formik.values.maintenanceDate}
                            label='When should this maintenance start?'
                            handleChange={(value) => handleFormChange("maintenanceDate", value)}
                            errors={formik.touched.maintenanceDate && formik.errors.maintenanceDate}
                          />
                        </div>
                        <div className='col-span-1 sm:col-span-2 mt-2'>
                          <div>
                            <span className='text-text-main'>Which jobs should be done?</span>
                          </div>
                          <div className='flex flex-wrap items-center gap-2 bg-bg-main rounded-lg border border-border-card-main p-2'>
                            {jobs.map((j) => {
                              const isSet = !!selectedJobs.includes(j.id);
                              return (
                                <div
                                  key={j.id}
                                  onClick={() => setJob(j.id)}
                                  className={`inline-flex items-center rounded-xl px-2 py-0.5 border ${
                                    isSet
                                      ? "bg-green-500/50 border-green-500"
                                      : "bg-bg-card-main border-border-card-main"
                                  } cursor-pointer`}
                                >
                                  <span className='text-sm text-text-main'>
                                    {j.attributes.nameEL}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='flex justify-end'>
                    <div className='flex items-center gap-2'>
                      <Button
                        handleClick={() =>
                          openMaintenance ? handleCancel() : setOpenMaintenance(!openMaintenance)
                        }
                        classes={`p-2 text-white ${
                          openMaintenance ? "bg-bg-btn-cancel" : "bg-bg-btn-primary"
                        }`}
                      >
                        {openMaintenance ? "Cancel" : "Start new maintenance"}
                      </Button>
                      {openMaintenance && (
                        <Button
                          handleClick={handleStart}
                          isLoading={isLoadingMaintenance}
                          disabled={isLoadingMaintenance || !formik.isValid}
                          classes={`bg-blue-500 px-2 py-2 text-white ${
                            isLoadingMaintenance && "pr-8"
                          }`}
                        >
                          Start
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div>
                <MaintenancesPageState
                  carId={car.id}
                  isFiltersOpened={false}
                  shouldRefresh={refreshMaintenances}
                />
              </div>
            </div>
          ) : (
            <DamagesPageState carId={car.id} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CarPageView;
