import Parse from "parse";
import Utils from "../../../utils/Utils";
import { useState, useEffect } from "react";
import useUser from "../../../hooks/useUser";
import Loader from "../../../components/Loader";
import useToaster from "../../../hooks/useToaster";
import { getViewportWidth } from "../../../utils/Viewport";
import UserMessagesPageView from "../view/UserMessagesPageView";

const UserMessagesPageState = ({ userId = null, shouldUpdateComms }) => {
  const userHook = useUser();
  const toaster = useToaster();
  const windowSize = getViewportWidth();
  const [filters, setFilters] = useState({});
  const isExternalPage = !Utils.isNull(userId);
  const [isLoading, setLoading] = useState(true);
  const [userMessages, setUserMessages] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [isLoadingMessage, setLoadingMessage] = useState(false);
  const [sorting, setSorting] = useState([{ columnName: "createdAt", direction: "desc" }]);
  const [pagination, setPagination] = useState({
    page: 0,
    size: isExternalPage ? 3 : windowSize === "MOBILE" ? 10 : 25,
  });

  const getUserMessages = async () => {
    const UserMessages = Parse.Object.extend("UserMessage");
    const query = new Parse.Query(UserMessages);
    query.include("user");
    query.include("sentBy");

    if (!!userId) {
      const userPointer = {
        __type: "Pointer",
        className: "_User",
        objectId: userId,
      };
      query.equalTo("user", userPointer);
    }

    try {
      if (Object.keys(filters).length > 0) {
        if (filters?.recipient) {
          let recipient = Parse.Object.extend("_User");
          const q = new Parse.Query(recipient);
          q.contains("username", filters.recipient.trim(), "i");
          recipient = await q.first();
          query.equalTo("user", recipient);
        }
        if (filters?.sender) {
          let sender = Parse.Object.extend("_User");
          const q = new Parse.Query(sender);
          q.contains("username", filters.sender.trim(), "i");
          sender = await q.first();
          query.equalTo("sentBy", sender);
        }
        filters.type && query.equalTo("type", filters.type);
        filters.isSeen && query.equalTo("seen", filters.isSeen);
        filters.from && query.greaterThanOrEqualTo("createdAt", filters.from);
        filters.to && query.lessThanOrEqualTo("createdAt", filters.to);
      }

      if (sorting[0]?.direction === "asc") {
        query.ascending(sorting[0].columnName);
      } else {
        query.descending(sorting[0].columnName);
      }
      query.limit(pagination.size);
      query.skip(pagination.page * pagination.size);
      query.withCount(true);
      query
        .find({ useMasterKey: true })
        .then((r) => {
          setUserMessages(r.results);
          setTotalElements(r.count);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const handleFilterChange = (field, value) => {
    setLoading(true);
    setFilters((prev) => ({ ...prev, [field]: value?.value ?? value }));
    (Utils.isNull(value) || value === "") &&
      setFilters((prev) => {
        const obj = { ...prev };
        delete obj[field];
        return obj;
      });
    setPagination({ ...pagination, page: 0 });
  };

  const handleClearFilters = (e) => {
    setLoading(true);
    e.stopPropagation();
    setFilters({});
  };

  const handleSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPagination({ ...pagination, page: page });
  };

  const handlePageSizeChange = (size) => {
    setLoading(true);
    setPagination({ ...pagination, size: size });
  };

  const handleSendMessage = async (_, form) => {
    setLoading(true);
    setLoadingMessage(true);
    return await userHook
      .handleSendMessages([userId], form)
      .then(() => {
        setLoading(false);
        setLoadingMessage(false);
        getUserMessages();
        return true;
      })
      .catch((e) => {
        console.error(e.message);
        return false;
      });
  };

  const handleSendEmail = async (_, form) => {
    setLoading(true);
    setLoadingMessage(true);
    return await userHook
      .handleSendEmail(userId, form, shouldUpdateComms)
      .then(() => {
        setLoading(false);
        setLoadingMessage(false);
        return true;
      })
      .catch((e) => {
        console.error(e.message);
        toaster.error(e.message);
        return false;
      });
  };

  useEffect(() => {
    if (filters.phone?.length > 0 || filters.username?.length > 0 || filters.lastName?.length > 0) {
      const timer = setTimeout(() => {
        getUserMessages();
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      getUserMessages();
    }
  }, [filters, sorting, pagination]);

  useEffect(() => {
    getUserMessages();
  }, []);

  return (
    <>
      {userMessages ? (
        <UserMessagesPageView
          userId={userId}
          filters={filters}
          sorting={sorting}
          isLoading={isLoading}
          pagination={pagination}
          userMessages={userMessages}
          totalElements={totalElements}
          handleSorting={handleSorting}
          isExternalPage={isExternalPage}
          handleSendEmail={handleSendEmail}
          isLoadingMessage={isLoadingMessage}
          handlePageChange={handlePageChange}
          handleSendMessage={handleSendMessage}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handlePageSizeChange={handlePageSizeChange}
        />
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default UserMessagesPageState;
