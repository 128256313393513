import Parse from "parse";
import Loader from "./Loader";
import useUser from "../hooks/useUser";
import { Tooltip } from "@mui/material";
import useToaster from "../hooks/useToaster";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faCircle, faUser } from "@fortawesome/free-solid-svg-icons";

const NotificationsPanel = ({ countUnseenNotifs, setNotifsPanelOpen }) => {
  const pageSize = 10;
  const timer = useRef(null);
  const userHook = useUser();
  const toaster = useToaster();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const session = userHook.getCurrentSession();
  const [isLoading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const initNotifications = async () => {
    const notifications = await getNotifications();

    if (notifications?.length > 0) setNotifications(notifications);
  };

  const getNotifications = async () => {
    setLoading(true);
    try {
      const Notifications = await new Parse.Query("Notifications")
        .includeAll()
        .descending("createdAt")
        .limit(pageSize)
        .skip(pageSize * page)
        .find({ useMasterKey: true });

      const data = await Promise.all(
        Notifications.map(async (n) => {
          return {
            id: n.id,
            type: n.attributes.type,
            action: n.attributes.action,
            content: n.attributes.content,
            entityID: n.attributes.entityID,
            isSeen: await checkIsSeen(n.id),
          };
        })
      );
      setLoading(false);
      return data;
    } catch (e) {
      console.error(e.message);
      setLoading(false);
    }
  };

  const loadMore = async () => {
    setLoading(true);
    setPage((prevPage) => prevPage + 1);
  };

  const markSeen = async (e, notifId) => {
    if (!!e) e.preventDefault();
    try {
      const User = new Parse.Object("_User").set("objectId", session.user.objectId);
      const Notif = new Parse.Object("Notifications").set("objectId", notifId);
      const isSeenRelation = Notif.relation("isSeen");
      isSeenRelation.add(User);

      await Notif.save(null, { useMasterKey: true });

      const newNotifs = notifications.map((n) => {
        if (n.id === notifId) {
          return { ...n, isSeen: true };
        } else {
          return n;
        }
      });
      setNotifications(newNotifs);
      countUnseenNotifs();
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const markSeenAll = async () => {
    try {
      await Promise.all(
        notifications.map((n) => {
          if (!n.isSeen) {
            return markSeen(null, n.id);
          }
        })
      ).then(() => {
        initNotifications();
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const checkIsSeen = async (notifId) => {
    const User = new Parse.Object("_User").set("objectId", session.user.objectId);
    const isSeen = await new Parse.Query("Notifications")
      .equalTo("objectId", notifId)
      .equalTo("isSeen", User)
      .first({ useMasterKey: true });

    if (!!isSeen) {
      return true;
    } else {
      return false;
    }
  };

  const getIcon = (type) => {
    if (type === "USER") {
      return faUser;
    } else if (type === "CAR") {
      return faCar;
    }
  };

  const handleMouseLeave = () => {
    timer.current = setTimeout(() => {
      setNotifsPanelOpen(false);
    }, 1000);

    return () => {
      clearTimeout(timer.current);
    };
  };

  const handleMouseEnter = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  };

  const handleRedirect = (notification) => {
    markSeen(null, notification.id);
    const path =
      notification.type === "USER"
        ? "/users/" + notification.entityID
        : "/cars/" + notification.entityID;

    navigate(path);
  };

  useEffect(() => {
    initNotifications();
  }, []);

  useEffect(() => {
    if (page > 0) {
      const loadMoreNotifications = async () => {
        const notifications = await getNotifications();

        if (notifications?.length > 0)
          setNotifications((prev) => {
            const newNotifs = [...prev];
            newNotifs.push(...notifications);
            return newNotifs;
          });
      };

      loadMoreNotifications();
    }
  }, [page]);

  return (
    <div
      className='notifications-panel'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='title'>
        <span>Notifications</span>
        <span className='cursor-pointer select-none' onClick={markSeenAll}>
          Mark all seen
        </span>
      </div>
      <div className='list'>
        <Loader isLoading={isLoading} />
        {notifications?.map((n) => {
          return (
            <div className='item'>
              <div key={n.id} className='flex items-center gap-3' onClick={() => handleRedirect(n)}>
                <FontAwesomeIcon icon={getIcon(n.type)} />
                <div className='flex'>
                  <span
                    className={`leading-5 text-base w-70 break-words ${!n.isSeen && "font-bold"}`}
                  >
                    {n.content}
                  </span>
                </div>
              </div>
              {!n.isSeen && (
                <Tooltip title='Click to mark as seen'>
                  <FontAwesomeIcon
                    size='sm'
                    icon={faCircle}
                    className='text-blue-400'
                    onClick={(e) => markSeen(e, n.id)}
                  />
                </Tooltip>
              )}
            </div>
          );
        })}
      </div>
      <div className='mt-2'>
        <div className='text-center'>
          <span className='text-lg font-bold cursor-pointer' onClick={loadMore}>
            Load more
          </span>
        </div>
      </div>
    </div>
  );
};

export default NotificationsPanel;
