import Parse from "parse";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import useToaster from "../../../hooks/useToaster";
import HappyHourSettingsPageView from "../view/HappyHourSettingsPageView";

const HappyHourSettingsPageState = ({}) => {
  const toaster = useToaster();
  const [isLoading, setLoading] = useState(false);
  const [happyHour, setHappyHour] = useState(null);

  const getHappyHour = async () => {
    setLoading(true);
    try {
      const HappyHour = await new Parse.Query("HappyHour").first({ useMasterKey: true });
      setHappyHour(HappyHour);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  useEffect(() => {
    getHappyHour();
  }, []);

  return (
    <>
      {!!!happyHour ? (
        <Loader isLoading={isLoading} />
      ) : (
        <HappyHourSettingsPageView happyHour={happyHour} isLoading={isLoading} />
      )}
    </>
  );
};

export default HappyHourSettingsPageState;
