import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Utils from "../../../utils/Utils";
import CarFuelIndicator from "./CarFuelIndicator";
import Toggler from "../../../components/Toggler";
import { dateFormatter } from "../../../utils/Dates";
import { carSerializer } from "../../../models/serializers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import { getViewportWidth, isBelow } from "../../../utils/Viewport";
import { faCircleCheck, faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import { SortingState, RowDetailState, PagingState, CustomPaging } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  Toolbar,
  PagingPanel,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

const CarsTable = ({
  cars,
  sorting,
  isAdding,
  isLoading,
  pagination,
  carHovered,
  setIsAdding,
  expandedRows,
  totalElements,
  handleSorting,
  handlePageChange,
  handleMarkerClick,
  handleExpandedRows,
  handlePageSizeChange,
  handleToggleAvailable,
}) => {
  const windowSize = getViewportWidth();
  const sortableColumns = [];

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props} className='sortable-column-label'>
          {props.children}
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const resolveStatusColor = (data) => {
    const car = carSerializer(data);
    const isRiding = car.isRiding;
    const isAvailable = car.isAvailable;
    const isUnderMaintenance = car.underMaintenance;

    if (carHovered === car.id) return "blue-500";
    else if (isRiding) return "green-500";
    else if (isUnderMaintenance) return "orange-500";
    else if (!isAvailable) return "red-500";
  };

  const resolveCarPlate = (data) => {
    return (
      <div className='flex items-center gap-2'>
        <Link className='link' to={`/cars/${data.id}`}>
          <span className='font-semibold'>{data.attributes.carPlate}</span>
        </Link>
      </div>
    );
  };

  const resolveFuel = (data) => {
    const fuel = parseInt(data.attributes?.fuel ?? data.fuel);

    return <CarFuelIndicator fuel={fuel} isCircular />;
  };

  const resolveNote = (data) => {
    const note = data.attributes.notes;
    const hasNotes = !Utils.isEmpty(data.attributes.notes);

    return (
      <>
        {hasNotes && (
          <Tooltip title={note} placement='top'>
            <FontAwesomeIcon
              className='text-text-main cursor-pointer'
              size={isBelow("SM") ? "lg" : "xl"}
              icon={isBelow("SM") ? faNoteSticky : faCircleCheck}
            />
          </Tooltip>
        )}
      </>
    );
  };

  const resolveIsAvailable = (data) => {
    const isAvailable = data.attributes?.isAvailable ?? data.isAvailable;
    return (
      <div className='flex items-center justify-center'>
        <Toggler
          toggle={isAvailable}
          handleToggle={(toggle) => handleToggleAvailable(toggle, data.id)}
          disabled={isLoading}
        />
      </div>
    );
  };

  const resolveLatestRide = (data) => {
    return dateFormatter(data.latestRide);
  };

  const resolveColumns = () => {
    if (isBelow("SM")) {
      return [
        {
          name: "carPlate",
          title: windowSize === "MOBILE" ? "Cars" : "Car Plate",
          getCellValue: resolveCarPlate,
        },
      ];
    } else {
      return [
        {
          name: "carPlate",
          title: "Plate",
          getCellValue: resolveCarPlate,
        },
        {
          name: "brand",
          title: "Brand",
          getCellValue: (data) => data.attributes.brand,
        },
        {
          name: "model",
          title: "Model",
          getCellValue: (data) => data.attributes.model,
        },
        {
          name: "address",
          title: "Address",
          getCellValue: (data) => data.attributes.address,
        },
        {
          name: "latestRide",
          title: "Last Rented",
          getCellValue: resolveLatestRide,
        },
        { name: "notes", title: "Notes", getCellValue: resolveNote },
        {
          name: "fuel",
          title: "Fuel",
          getCellValue: resolveFuel,
        },
        {
          name: "isAvailable",
          title: "Available",
          getCellValue: resolveIsAvailable,
        },
      ];
    }
  };

  const columns = resolveColumns();

  const rowComponent = (props) => {
    const car = carSerializer(props.row);
    const color = resolveStatusColor(props.row);

    return (
      <Table.Row
        {...props}
        onClick={() => handleMarkerClick(car.id)}
        className={`cursor-pointer bg-${color} bg-opacity-15`}
      />
    );
  };

  const cellComponent = (props) => {
    if (isBelow("SM")) {
      const car = carSerializer(props.row);

      return (
        <Table.Cell {...props} style={{ padding: "7px 0" }}>
          <div className='flex items-center justify-between gap-x-2'>
            <div>
              <div className='flex items-center pl-1 gap-x-1'>
                {resolveCarPlate(props.row)}
                <div className='flex items-center gap-x-1 text-wrap'>
                  <span>{car.brand}</span>
                  <span>{car.model}</span>
                </div>
              </div>
              <div className='text-wrap'>
                <span>{car.address}</span>
              </div>
              <div>Last Rented: {resolveLatestRide(props.row)}</div>
            </div>
            <div className='flex gap-2 items-center'>
              {resolveNote(props.row)} {resolveFuel(car)}
            </div>
          </div>
        </Table.Cell>
      );
    } else {
      return <Table.Cell {...props} style={{ padding: "6px 0" }} />;
    }
  };

  const toolbar = () => {
    return (
      <>
        {!isAdding && (
          <div className='flex ml-4 mt-4'>
            <div
              onClick={() => setIsAdding(!isAdding)}
              className='flex gap-2 items-center justify-start text-text-main cursor-pointer'
            >
              <FontAwesomeIcon icon={faSquarePlus} size='sm' />
              <span>Add Car</span>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className='relative'>
      <Grid rows={cars} columns={columns} getRowId={(row) => row.id}>
        <RowDetailState expandedRowIds={expandedRows} onExpandedRowIdsChange={handleExpandedRows} />
        <SortingState sorting={sorting} onSortingChange={handleSorting} />
        <PagingState
          currentPage={pagination.page}
          pageSize={pagination.size}
          onCurrentPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
        <CustomPaging totalCount={totalElements} />
        <Table
          columnExtensions={[
            {
              columnName: "carPlate",
              wordWrapEnabled: false,
              width: windowSize === "MOBILE" ? "auto" : "110",
            },
            { columnName: "brand", wordWrapEnabled: true, width: "120" },
            { columnName: "model", wordWrapEnabled: true, width: "120" },
            { columnName: "address", wordWrapEnabled: true, width: "auto" },
            {
              columnName: "latestRide",
              wordWrapEnabled: true,
              width: "100",
            },
            {
              columnName: "notes",
              wordWrapEnabled: true,
              width: "50",
              align: "center",
            },
            {
              columnName: "fuel",
              wordWrapEnabled: true,
              width: "60",
            },
            {
              columnName: "isAvailable",
              wordWrapEnabled: true,
              width: "80",
              align: "center",
            },
          ]}
          cellComponent={cellComponent}
          rowComponent={rowComponent}
        />
        <Toolbar rootComponent={toolbar} />
        <TableHeaderRow
          sortLabelComponent={TableHeaderRowSortLabelComponent}
          showSortingControls={true}
        />
        <PagingPanel pageSizes={[10, 25, 50, 100]} />
      </Grid>
    </div>
  );
};

export default CarsTable;
