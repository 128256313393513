import Parse from "parse";
import "../styles/styles.scss";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import Renting from "../../../models/Renting";
import { date, object, ref, string } from "yup";
import UserPageView from "../view/UserPageView";
import Loader from "../../../components/Loader";
import useToaster from "../../../hooks/useToaster";
import { dateFormatter } from "../../../utils/Dates";
import { userSerializer } from "../../../models/serializers";

const UserPageState = () => {
  const userHook = useUser();
  const toaster = useToaster();
  const { userId } = useParams();
  const [user, setUser] = useState();
  const [totals, setTotals] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoadingForm, setLoadingForm] = useState(false);
  const [isLoadingUser, setLoadingUser] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [isLoadingTotals, setLoadingTotals] = useState(false);
  const [duplicateLicenseNo, setDuplicateLicenseNo] = useState(null);

  const validationSchema = object().shape({
    licenseId: string().required("License ID is required"),
    licenseIssueDate: date()
      .required("License issue date is required")
      .max(ref("licenseExpiryDate"), "License issue date cannot be after License expiry date"),
    licenseExpiryDate: date()
      .required("License expiry date is required")
      .min(ref("licenseIssueDate"), "License expiry date cannot be before License issue date"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      licenseId: "",
      licenseIssueDate: null,
      licenseExpiryDate: null,
    },
  });

  const getUser = async () => {
    const Users = Parse.Object.extend("_User");
    const query = new Parse.Query(Users);
    query.equalTo("objectId", userId);
    query.include("country");

    try {
      const u = await query.first();
      const uS = userSerializer(u);

      if (!!uS.license.id) {
        const checkDuplicate = await new Parse.Query("_User")
          .includeAll()
          .notEqualTo("objectId", uS.id)
          .equalTo("licenseNo", uS.license.id)
          .first({ useMasterKey: true });
        setDuplicateLicenseNo(userSerializer(checkDuplicate));
      }

      setUser(uS);
      formik.setValues({
        licenseId: uS?.license?.id,
        licenseIssueDate: u.attributes.licenseIssueDate,
        licenseExpiryDate: u.attributes.licenseExpiryDate,
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const getTotals = async () => {
    setLoadingTotals(true);
    try {
      const totalRentings = await new Parse.Query("Rentings")
        .equalTo("driver", {
          __type: "Pointer",
          className: "_User",
          objectId: userId,
        })
        .includeAll()
        .findAll({
          useMasterKey: true,
        });

      const discountsCalculated = await Renting.provideTotalBill(totalRentings);

      setTotals({
        rentings: totalRentings.length,
        revenue: discountsCalculated
          .filter((r) => r.attributes.isPaid)
          .reduce((a, b) => a + (b.attributes.totalBill || 0), 0),
      });
      setLoadingTotals(false);
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const checkSetReviewed = async () => {
    if (!!user) {
      const isReviewed = user.isReviewed;

      if (!isReviewed) {
        const isEnabled = user.isEnabled;
        const isRejected = user.isRejected;
        const isAuthorized = user.isAuthorized;

        if (isEnabled && !isRejected && !isAuthorized) {
          await new Parse.Object("_User")
            .set("objectId", user.id)
            .set("isReviewed", true)
            .save(null, { useMasterKey: true });
        }
      }
    }
  };

  const getPaymentMethods = async () => {
    const PaymentMethod = await new Parse.Query("PaymentMethod")
      .equalTo("user", {
        __type: "Pointer",
        className: "_User",
        objectId: userId,
      })
      .first({ useMasterKey: true });
    setPaymentMethod(PaymentMethod);
  };

  const handleToggleEmailVerified = async (toggle) => {
    setLoading(true);
    await userHook.handleToggleEmailVerified(toggle, userId);
  };

  const handleTogglePhoneVerified = async (toggle) => {
    setLoading(true);
    await userHook.handleTogglePhoneVerified(toggle, userId);
  };

  const handleUserAuthorization = async (action, _) => {
    setLoading(true);
    await userHook.handleUserAuthorization(action, userId);
  };

  const handleToggleEnabled = async (toggle) => {
    setLoading(true);
    await userHook.handleToggleEnabled(toggle, userId);
  };

  const handleToggleLocked = async (toggle) => {
    setLoading(true);
    await userHook.handleToggleLocked(toggle, userId);
  };

  const handleNotesSubmit = async (value) => {
    setLoading(true);
    await userHook.handleNotesSubmit(value, userId);
  };

  const handleFormChange = (field, value) => {
    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  };

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    if (formik.isValid) {
      setLoadingForm(true);
      try {
        await userHook.handleEdit(userId, formik).then(() => {
          setUser((prev) => ({
            ...prev,
            license: {
              ...prev.license,
              id: formik.values.licenseId,
              issue: dateFormatter(formik.values.licenseIssueDate),
              expire: dateFormatter(formik.values.licenseExpiryDate),
            },
          }));
          setIsEditing(false);
          setLoadingForm(false);
        });
      } catch (e) {
        console.error(e.message);
        toaster.error(e.message);
      }
    }
  };

  const handleSubmitDocument = async (document, file) => {
    try {
      let fileBase64 = null;
      let fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(console.error("Problem parsing input file."));
          toaster.error("Problem parsing input file.");
        };

        fileReader.onload = async (fileLoadedEvent) => {
          fileBase64 = fileLoadedEvent.target.result;
          const data = fileBase64.split(",")[1];

          const parseFile = new Parse.File(file.name, { base64: data });

          const newDocument = new Parse.Object("_User");
          newDocument.set("objectId", userId);
          newDocument.set(document, parseFile);

          await newDocument.save(null, { useMasterKey: true }).then(() => {
            getUser().then(() => {
              resolve("ok");
            });
          });
        };

        fileReader.readAsDataURL(file);
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  useEffect(() => {
    checkSetReviewed();
  }, [user]);

  useEffect(() => {
    getUser().finally(() => {
      setLoading(false);
    });
  }, [isLoading]);

  useEffect(() => {
    setLoadingUser(true);
    
    getTotals();
    getPaymentMethods();

    getUser().finally(() => {
      setLoadingUser(false);
    });
  }, [userId]);

  return (
    <>
      {!user || isLoadingUser ? (
        <Loader isLoading={isLoading || isLoadingUser} />
      ) : (
        <UserPageView
          user={user}
          form={formik}
          totals={totals}
          isEditing={isEditing}
          isLoading={isLoading}
          handleEdit={handleEdit}
          handleSave={handleSave}
          paymentMethod={paymentMethod}
          isLoadingForm={isLoadingForm}
          isLoadingTotals={isLoadingTotals}
          handleFormChange={handleFormChange}
          handleNotesSubmit={handleNotesSubmit}
          duplicateLicenseNo={duplicateLicenseNo}
          handleToggleLocked={handleToggleLocked}
          handleToggleEnabled={handleToggleEnabled}
          handleSubmitDocument={handleSubmitDocument}
          handleUserAuthorization={handleUserAuthorization}
          handleToggleEmailVerified={handleToggleEmailVerified}
          handleTogglePhoneVerified={handleTogglePhoneVerified}
        />
      )}
    </>
  );
};

export default UserPageState;
