import HappyHourCardState from "../../../components/with-state/state/HappyHourCardState";

const HappyHourSettingsPageView = ({ happyHour, isLoading }) => {
  return (
    <>
      <div className='title'>
        <span>Happy Hour Settings</span>
      </div>
      <div className='settings-content'>
        <HappyHourCardState happyHour={happyHour} isLoading={isLoading} />
      </div>
    </>
  );
};

export default HappyHourSettingsPageView;
