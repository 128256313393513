import "./styles/Toggler.scss";

const Toggler = ({ toggle, handleToggle, isBlue = false, disabled = false }) => {
  const handleClick = () => {
    !disabled && handleToggle(!toggle);
  };

  return (
    <div
      className={`toggler w-[35px] min-w-[35px] h-[20px] relative rounded-full border border-solid pl-1 pr-1 pt-1 pb-1 ${
        disabled ? "cursor-default opacity-50" : "cursor-pointer"
      } ${
        toggle
          ? isBlue
            ? "border-blue-500 bg-blue-500"
            : "border-green-500 bg-green-500"
          : "border-gray-400 bg-gray-400"
      }`}
      onClick={handleClick}
    >
      <div
        className={`block w-[15px] h-[15px] bg-white absolute top-1/2 -translate-y-1/2 rounded-full ${
          toggle ? "thumb-enabled" : "thumb-disabled"
        }`}
      ></div>
    </div>
  );
};

export default Toggler;
