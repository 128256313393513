import Parse from "parse";
import { useFormik } from "formik";
import { object, string } from "yup";
import User from "../../../models/User";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { findOption } from "../../../utils/Forms";
import useToaster from "../../../hooks/useToaster";
import Button from "../../../components/form/Button";
import FormRadio from "../../../components/form/FormRadio";
import FormInput from "../../../components/form/FormInput";
import FormSelect from "../../../components/form/FormSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormTextarea from "../../../components/form/FormTextarea";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import AddNewMessageTemplateModal from "../../../components/AddNewMessageTemplateModal";
import { userMessageTemplateSerializer, userSerializer } from "../../../models/serializers";

const SendUserEmailModal = ({ userId, isOpen, isLoading, toggleModal, handleSendEmail }) => {
  const toaster = useToaster();
  const [templates, setTemplates] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [submitMsgesSuccess, setSubmitMsgesSucces] = useState(false);
  const [selectedTemplateLanguage, setSelectedTemplateLanguage] = useState("EL");
  const recipient = userSerializer(new Parse.Object("_User").set("objectId", userId));
  const [isAddMessageTemplateModalOpen, toggleAddMessageTemplateModal] = useState(false);
  const [responseState, setResponseState] = useState({
    type: null,
    message: null,
  });

  const getRegards = () => {
    const regards = {
      el: `<div>
            <div style="margin-bottom: 20px;">Για οποιαδήποτε απορία ή διευκρίνηση, μη διστάσετε να επικοινωνήσετε μαζί μας στο: <a href="tel:+302102380803">+302102380803</a>.</div>
            <div>Με εκτίμηση,</div>
            <div>Caroo Customer Care Team</div>
          </div>`,
      en: `<div>
            <div style="margin-bottom: 20px;">For further information, do not hesitate to contact with us at: <a href="tel:+302102380803">+302102380803</a>.</div>
            <div>Kind regards,</div>
            <div>Caroo Customer Care Team</div>
          </div>`,
    };

    return regards[String(selectedTemplateLanguage).toLowerCase()];
  };

  const validationSchema = object().shape({
    type: string().required("Type is required"),
    title: string().required("Title is required"),
    message: string().required("Message is required"),
    greeting: string().required("Greeting is required"),
    recipient: string().required("Recipient is required"),
    regards: string().required("Regards is required"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      type: "INFO",
      title: "",
      message: "",
      greeting: "",
      recipient: recipient.username,
      regards: getRegards(),
    },
  });
  const userMessageTypeOptions = Object.keys(User.MESSAGE_TYPES).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(t),
  }));

  const getMessagesTemplates = async () => {
    const MessageTemplates = await new Parse.Query("UserMessageTemplates").findAll({
      useMasterKey: true,
    });
    const serialized = MessageTemplates.map((t) => userMessageTemplateSerializer(t));
    setTemplates(serialized);
  };

  const setTemplate = async (template) => {
    setSelectedTemplate(template);
  };

  const templateLanguageChange = (value) => {
    setSelectedTemplateLanguage(value);
  };

  const handleSubmit = async () => {
    Object.keys(formik.values).map((key) => formik.setFieldTouched(key));

    if (!formik.isValid) return;
    try {
      await handleSendEmail(userId, formik.values).then((r) => {
        if (r) {
          setSubmitMsgesSucces(true);
          setResponseState({
            type: true,
            message: "Email has been sent successfully.",
          });
          setTimeout(() => {
            formik.setValues({
              type: "INFO",
              title: "",
              message: "",
            });
            toggleModal(false);
            setSubmitMsgesSucces(false);
          }, 1500);
        } else {
          setSubmitMsgesSucces(false);
          setResponseState({
            type: false,
            message: "Something went wrong. Try again.",
          });
        }
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleCancel = () => {
    toggleModal(false);
    formik.setValues({
      type: "INFO",
      title: "",
      message: "",
    });
    setSubmitMsgesSucces(false);
    setResponseState({ type: null, message: null });
  };

  useEffect(() => {
    getMessagesTemplates();
  }, []);

  useEffect(() => {
    formik.validateForm();
  }, []);

  useEffect(() => {
    setTemplateOptions(
      templates.map((t) => ({
        value: t.id,
        label: t[String(selectedTemplateLanguage).toLowerCase()].title,
      }))
    );
  }, [templates]);

  useEffect(() => {
    const tmpl = templates?.find((t) => t.id === selectedTemplate?.value);

    if (!!tmpl) {
      formik.setValues({
        type: tmpl?.type,
        greeting: `${
          selectedTemplateLanguage === "EL"
            ? recipient.gender === "male"
              ? "Αγαπητέ"
              : "Αγαπητή"
            : "Dear"
        } ${Utils.textFirstOnlyUpper(recipient.firstName)} ${Utils.textFirstOnlyUpper(
          recipient.lastName
        )},`,
        title: tmpl[String(selectedTemplateLanguage).toLowerCase()]?.title,
        message: tmpl[String(selectedTemplateLanguage).toLowerCase()]?.message,
        recipient: recipient.username,
        regards: getRegards(),
      });
    }
  }, [selectedTemplate, selectedTemplateLanguage]);

  return (
    <>
      <Modal
        open={isOpen}
        title='Send user email'
        classes={"w-4/5 lg:w-1/2 2xl:w-1/3"}
        handleToggle={() => toggleModal(!isOpen)}
      >
        <div>
          <div className='mb-1 relative'>
            <FormSelect
              label='Template'
              options={templateOptions}
              handleChange={setTemplate}
              value={
                !Utils.isNull(selectedTemplate)
                  ? findOption(templateOptions, selectedTemplate.value)
                  : null
              }
            />
            <div className='absolute left-20 top-0 flex items-center gap-2'>
              <FormRadio
                label='EL'
                value='EL'
                name='language'
                handleChange={templateLanguageChange}
                checked={selectedTemplateLanguage === "EL"}
              />
              <FormRadio
                label='EN'
                value='EN'
                name='language'
                handleChange={templateLanguageChange}
                checked={selectedTemplateLanguage === "EN"}
              />
            </div>
            <div className='absolute right-0 top-0'>
              <div
                className='flex items-center gap-1 text-text-main cursor-pointer'
                onClick={() => toggleAddMessageTemplateModal(true)}
              >
                <FontAwesomeIcon icon={faSquarePlus} />
                <span className='text-sm sm:text-base'>Add Template</span>
              </div>
            </div>
          </div>
          <div className='mb-2'>
            <FormSelect
              label='Type'
              options={userMessageTypeOptions}
              errors={formik.touched.type && formik.errors.type}
              handleChange={(value) => {
                formik.setFieldTouched("type");
                formik.setFieldValue("type", value.value);
              }}
              value={
                !Utils.isNull(formik.values.type)
                  ? findOption(userMessageTypeOptions, formik.values.type)
                  : null
              }
            />
          </div>
          <div className='mb-2'>
            <FormInput
              type='text'
              label='Greeting'
              value={formik.values.greeting}
              errors={formik.touched.greeting && formik.errors.greeting}
              placeholder='Greeting'
              handleChange={(e) => {
                formik.setFieldTouched("greeting");
                formik.setFieldValue("greeting", e.target.value);
              }}
            />
          </div>
          <div className='mb-2'>
            <FormInput
              type='text'
              label='Title'
              value={formik.values.title}
              errors={formik.touched.title && formik.errors.title}
              placeholder='Title'
              handleChange={(e) => {
                formik.setFieldTouched("title");
                formik.setFieldValue("title", e.target.value);
              }}
            />
          </div>
          <div className='mb-2'>
            <FormTextarea
              rows={4}
              label='Message'
              value={formik.values.message}
              errors={formik.touched.message && formik.errors.message}
              placeholder='Type the message to be sent to the users here..'
              handleChange={(value) => {
                formik.setFieldTouched("message");
                formik.setFieldValue("message", value);
              }}
            />
          </div>
          <div className='grid grid-cols-2 gap-2 mt-4'>
            <Button classes='bg-gray-200 py-2' disabled={isLoading} handleClick={handleCancel}>
              <span>Cancel</span>
            </Button>
            <Button
              isLoading={isLoading}
              handleClick={handleSubmit}
              classes={`bg-blue-500 py-2`}
              success={submitMsgesSuccess}
              disabled={isLoading || submitMsgesSuccess}
            >
              <span className='text-white'>Send</span>
            </Button>
          </div>
          {!Utils.isNull(responseState.type) && (
            <div className='mt-1'>
              <span
                className={`text-sm font-bold ${
                  responseState.type ? "text-green-400" : "text-red-400"
                }`}
              >
                {responseState.message}
              </span>
            </div>
          )}
        </div>
      </Modal>
      <AddNewMessageTemplateModal
        isOpen={isAddMessageTemplateModalOpen}
        toggleModal={toggleAddMessageTemplateModal}
        emitTemplate={(tmpl) => {
          const tmplS = userMessageTemplateSerializer(tmpl);
          setTemplates((prev) => [...prev, tmplS]);
        }}
      />
    </>
  );
};

export default SendUserEmailModal;
