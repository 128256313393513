import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingMedical } from "@fortawesome/free-solid-svg-icons";
import { AdvancedMarker, AdvancedMarkerAnchorPoint } from "@vis.gl/react-google-maps";

const MapMarker = ({
  car,
  zoom,
  carHovered,
  setMarkerRef,
  hoveringLabel,
  setHoveringLabel,
  handleMarkerClick,
}) => {
  const [scale, setScale] = useState(1);

  const resolveCarTagBg = (car) => {
    const isRiding = car.isRiding;
    const isAvailable = car.isAvailable;
    const isUnderMaintenance = car.underMaintenance;

    if (carHovered === car.id) return "is-hovered";
    else if (isRiding) return "is-riding";
    else if (isUnderMaintenance) return "is-under-maintenance";
    else if (!isAvailable) return "is-unavailable";
    else return "is-available";
  };

  const resolveHoveringScale = (carId) => {
    if (hoveringLabel === carId) {
      if (zoom < 11.99) return "scale-[1.3]";
      if (zoom > 12) return "scale-[1.7]";
    }
  };

  useEffect(() => {
    if (zoom < 11.99) setScale("scale-[1.1]");
    if (zoom > 12) setScale("scale-[1.3]");
  }, [zoom]);

  return (
    <AdvancedMarker
      key={car.id}
      className='cursor-pointer'
      zIndex={hoveringLabel === car.id ? 2 : 1}
      onClick={() => handleMarkerClick(car.id)}
      onMouseLeave={() => setHoveringLabel(null)}
      onMouseEnter={() => setHoveringLabel(car.id)}
      ref={(marker) => setMarkerRef(marker, car.id)}
      anchorPoint={AdvancedMarkerAnchorPoint.BOTTOM}
      position={{
        lat: car.location.latitude,
        lng: car.location.longitude,
      }}
    >
      <div
        id={car.id}
        className={`${resolveCarTagBg(car)} ${scale} ${resolveHoveringScale(
          car.id
        )} marker-car-tag`}
      >
        <div className='tag-title'>
          <span>{car.carPlate}</span>
          {!!car.maintenance && (
            <Tooltip title='Planned maintenance' placement='top'>
              <span className='relative w-4 h-4'>
                <FontAwesomeIcon size='lg' icon={faHandHoldingMedical} />
              </span>
            </Tooltip>
          )}
        </div>
      </div>
    </AdvancedMarker>
  );
};

export default MapMarker;
