import { useEffect, useState } from "react";
import { isBelow } from "../utils/Viewport";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GeneralSettingsPage from "../pages/configurations/GeneralSettingsPage";
import CampaignsSettingsPage from "../pages/configurations/CampaignsSettingsPage";
import HappyHourSettingsPage from "../pages/configurations/HappyHourSettingsPage";
import {
  faCog,
  faAnglesLeft,
  faVolumeHigh,
  faAnglesRight,
  faFaceLaughSquint,
} from "@fortawesome/free-solid-svg-icons";

const ConfigurationsLayout = ({}) => {
  const location = useLocation();
  const [path, setPath] = useState("general");
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const handleClick = () => {
    if (isBelow("MD")) {
      setSidebarVisible(false);
    }
  };

  useEffect(() => {
    const p = String(location.pathname).split("/");
    setPath(p[p.length - 1]);
  }, [location]);

  return (
    <div className='page-wrapper flex-1 flex flex-col'>
      <div className='page-with-sidebar flex-1'>
        <div className={`sidebar ${isSidebarVisible ? "visible" : ""}`}>
          <FontAwesomeIcon
            size='xl'
            icon={faAnglesLeft}
            onClick={() => setSidebarVisible(false)}
            className='absolute sm:hidden right-0 top-1 text-text-main'
          />
          <NavLink to={"/configurations/campaigns"} className='item' onClick={handleClick}>
            <div className='w-6'>
              <FontAwesomeIcon icon={faVolumeHigh} />
            </div>
            <span>Campaigns</span>
          </NavLink>
          <NavLink to={"/configurations/happy-hour"} className='item' onClick={handleClick}>
            <div className='w-6'>
              <FontAwesomeIcon icon={faFaceLaughSquint} />
            </div>
            <span>Happy Hour</span>
          </NavLink>
          <NavLink to={"/configurations/general"} className='item' onClick={handleClick}>
            <div className='w-6'>
              <FontAwesomeIcon icon={faCog} />
            </div>
            <span>General Settings</span>
          </NavLink>
        </div>

        <div className='content'>
          <FontAwesomeIcon
            size='xl'
            icon={faAnglesRight}
            onClick={() => setSidebarVisible(true)}
            className='absolute sm:hidden left-2 top-5 text-text-main'
          />
          {path === "general" ? (
            <GeneralSettingsPage />
          ) : path === "campaigns" ? (
            <CampaignsSettingsPage />
          ) : (
            <HappyHourSettingsPage />
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfigurationsLayout;
