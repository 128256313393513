import { useState } from "react";
import Filters from "../components/UserMessagesFilters";
import UserMessagesTable from "../components/UserMessagesTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SendUserEmailModal from "../components/SendUserEmailModal";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import SendUserMessagesModal from "../components/SendUserMessagesModal";

const UserMessagesPageView = ({
  userId,
  filters,
  sorting,
  isLoading,
  pagination,
  userMessages,
  totalElements,
  handleSorting,
  isExternalPage,
  handleSendEmail,
  isLoadingMessage,
  handlePageChange,
  handleSendMessage,
  handleClearFilters,
  handleFilterChange,
  handlePageSizeChange,
}) => {
  const [isEmailModalOpen, toggleEmailModal] = useState(false);
  const [isMessagesModalOpen, toggleMessagesModal] = useState(false);

  return (
    <div className='page-wrapper'>
      <div className={`page-content ${isExternalPage && "is-inner"}`}>
        {!isExternalPage && (
          <>
            <div className='page-title'>
              <span className='title'>USER MESSAGES</span>
            </div>
            <Filters
              filters={filters}
              isLoading={isLoading}
              handleFilterChange={handleFilterChange}
              handleClearFilters={handleClearFilters}
            />
          </>
        )}
        <div className='table-wrapper'>
          <div className='external-header'>
            <div className='title-inner'>
              <span>Messages</span>
            </div>
            <div className='flex items-center gap-2 sm:gap-4'>
              <div
                className='flex items-center gap-1 text-text-main cursor-pointer'
                onClick={() => toggleMessagesModal(true)}
              >
                <FontAwesomeIcon icon={faSquarePlus} />
                <span className='text-sm sm:text-base'>New Message</span>
              </div>
              {isExternalPage && (
                <div
                  className='flex items-center gap-1 text-text-main cursor-pointer'
                  onClick={() => toggleEmailModal(true)}
                >
                  <FontAwesomeIcon icon={faSquarePlus} />
                  <span className='text-sm sm:text-base'>Send Email</span>
                </div>
              )}
            </div>
          </div>
          <UserMessagesTable
            filters={filters}
            sorting={sorting}
            isLoading={isLoading}
            pagination={pagination}
            userMessages={userMessages}
            handleSorting={handleSorting}
            totalElements={totalElements}
            isExternalPage={isExternalPage}
            handlePageChange={handlePageChange}
            handleFilterChange={handleFilterChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
      <SendUserMessagesModal
        users={null}
        setSelection={null}
        isOpen={isMessagesModalOpen}
        handleRemoveRecipient={null}
        isLoading={isLoadingMessage}
        toggleModal={toggleMessagesModal}
        handleSendMessages={handleSendMessage}
      />
      <SendUserEmailModal
        userId={userId}
        isOpen={isEmailModalOpen}
        isLoading={isLoadingMessage}
        toggleModal={toggleEmailModal}
        handleSendEmail={handleSendEmail}
      />
    </div>
  );
};

export default UserMessagesPageView;
