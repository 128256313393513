const FormTextarea = ({ rows, label, value, errors, disabled, placeholder, handleChange }) => {
  return (
    <div className='form-control'>
      {label && <label className='text-text-main pl-1 mb-1'>{label}</label>}
      <textarea
        rows={rows}
        value={value}
        className={`w-full ${!!errors ? "is-invalid" : ""}`}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
      />
      {errors && (
        <div className='error'>
          <span>{errors}</span>
        </div>
      )}
    </div>
  );
};

export default FormTextarea;
