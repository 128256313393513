export default class Utils {
  static define(value, defaultValue = null) {
    if (defaultValue === undefined) defaultValue = null;
    return value === undefined || value === null ? defaultValue : value;
  }

  static defineNumber(value, defaultValue = null) {
    value = Utils.define(value, defaultValue);
    return typeof value === "number" ? value : Utils.define(defaultValue);
  }

  static defineString(value, defaultValue = null) {
    value = Utils.define(value, defaultValue);
    return typeof value === "string" ? value : Utils.define(defaultValue);
  }

  static defineToString(value, defaultValue = null) {
    value = Utils.define(value, defaultValue);
    if (value === null) return "";
    if (typeof value === "string") return value;
    if (typeof value === "number") return "" + value;
    return defaultValue;
  }

  static defineBoolean(value, defaultValue) {
    value = Utils.define(value);
    if (value === true || value === false) return value;
    return defaultValue;
  }

  static defineArray(value, defaultValue) {
    if (Array.isArray(value)) return value;
    if (Array.isArray(defaultValue)) return defaultValue;
    return null;
  }

  static isDefined(value) {
    if (value === undefined) return false;
    return true;
  }

  static isNull(value) {
    if (value === undefined || value === null) return true;
    return false;
  }

  static isEmpty(value) {
    if (value === undefined || value === null || value === "") return true;
    if (Array.isArray(value) && value.length === 0) return true;
    if (typeof value === "object" && Object.keys(value).length === 0) return true;
    if (typeof value === "string" && value.trim() === "") return true;
    return false;
  }

  static isBoolean(value) {
    if (typeof value == "boolean") return true;
  }

  static billFormatter(value) {
    if (Number.isInteger(value)) {
      return value + ".0";
    } else {
      return value;
    }
  }

  static removeWhitespaces(value) {
    return value.replace(/\s/g, "");
  }

  static textFirstOnlyUpper(text = null) {
    if (Utils.isEmpty(text)) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  static underscoreToSpace(value) {
    return value.replace(/_/g, " ");
  }

  static getLastNYears = (numberOfYears, limit = 0) => {
    const yearOptions = [];
    const currentYear = parseInt(new Date().getFullYear() - limit);
    for (let i = 0; i < numberOfYears; i++) {
      yearOptions.push(currentYear - i);
    }
    return yearOptions;
  };

  static getAgeOptions = (from, to) => {
    const ageOptions = [];
    for (let i = from; i <= to; i++) {
      ageOptions.push(i);
    }
    return ageOptions;
  };

  static prefillZero = (str) => {
    if (str.toString().length <= 1) {
      return (str = "0" + str);
    } else {
      return str;
    }
  };
}
