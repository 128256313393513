import dayjs from "dayjs";
import "../../styles/styles.scss";
import Utils from "../../utils/Utils";
import FormSelect from "./FormSelect";
import { useEffect, useState } from "react";
import { findOption } from "../../utils/Forms";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FormAgeInput = ({ label, errors, ageTo, ageFrom, handleChangeFrom, handleChangeTo }) => {
  const years = Utils.getAgeOptions(21, 85).map((i) => ({ value: i, label: i }));
  const [toOptions, setToOptions] = useState(years);

  const [to, setTo] = useState(null);
  const [from, setFrom] = useState(null);

  const handleFrom = (value) => {
    if (!!to) {
      if (to.value < value.value) setTo(null);
    }
    setFrom(value);
    const ageInDateFormat = dayjs().startOf("day").subtract(value.value, "year").toDate();
    handleChangeFrom(ageInDateFormat);
  };

  const handleTo = (value) => {
    setTo(value);
    const ageInDateFormat = dayjs()
      .endOf("day")
      .subtract(value.value + 1, "year")
      .add(1, "day")
      .toDate();
    handleChangeTo(ageInDateFormat);
  };

  const handleClear = () => {
    setTo(null);
    setFrom(null);
    handleChangeTo(null);
    handleChangeFrom(null);
  };

  useEffect(() => {}, [from, to]);

  useEffect(() => {
    if (!!from?.value) {
      const toOptions = Utils.getAgeOptions(from.value, 85);
      setToOptions(toOptions.map((i) => ({ value: i, label: i })));
    } else {
      setToOptions(years);
    }
  }, [from]);

  return (
    <div className='form-control age-input'>
      <div className='title'>
        {label && <label>{label}</label>}
        {(!!from || !!to) && (
          <FontAwesomeIcon icon={faTimes} className='cursor-pointer' onClick={handleClear} />
        )}
      </div>
      <div className='field'>
        <FormSelect
          type='text'
          options={years}
          placeholder='from'
          handleChange={handleFrom}
          value={!Utils.isNull(from) ? findOption(years, from.value) : null}
        />
        <FormSelect
          placeholder='to'
          disabled={!!!from}
          options={toOptions}
          handleChange={handleTo}
          value={!Utils.isNull(to) ? findOption(years, to.value) : null}
        />
      </div>
      {errors && (
        <div className='error'>
          <span>{errors}</span>
        </div>
      )}
    </div>
  );
};

export default FormAgeInput;
