export default class User {
  static ROLES = {
    ADMIN: "Admin",
    VIEWER: "Viewer",
    MAINTAINER: "Maintainer",
  };

  static ACTIONS = {
    AUTHORIZE: "AUTHORIZE",
    REAUTHORIZE: "REAUTHORIZE",
    REJECT: "REJECT",
  };

  static STATUS = {
    UNAUTHORIZED: "UNAUTHORIZED",
    PENDING: "PENDING",
    AUTHORIZED: "AUTHORIZED",
    REAUTHORIZED: "REAUTHORIZED",
    REJECTED: "REJECTED",
  };

  static MESSAGE_TYPES = {
    INFO: "INFO",
    WARNING: "WARNING",
    REMINDER: "REMINDER",
  };

  static GENDERS = {
    MALE: "MALE",
    FEMALE: "FEMALE",
  };

  static getStatus = (isRejected, isAuthorized) => {
    switch (true) {
      case isRejected && !isAuthorized:
        return this.STATUS.UNAUTHORIZED;
      case !isRejected && !isAuthorized:
        return this.STATUS.PENDING;
      case !isRejected && isAuthorized:
        return this.STATUS.AUTHORIZED;
      case isRejected && isAuthorized:
        return this.STATUS.REAUTHORIZED;
      default:
        return "UNDEFINED";
    }
  };

  static getStatusTooltip = (status) => {
    switch (status) {
      case User.STATUS.UNAUTHORIZED:
        return "User has registered but hasn't upload documents yet.";
      case User.STATUS.PENDING:
        return "User has uploaded documents and is awaiting admin aproval.";
      case User.STATUS.AUTHORIZED:
        return "User has been aproved by an admin and is ready to start using the Caroo service.";
      case User.STATUS.REAUTHORIZED:
        return "User has been reviewed by an admin and has been requested to reupload the documents again due to invalid information.";
      case User.STATUS.REJECTED:
        return "User has been rejected by an admin due to wrong documents or information uploaded and must repeat information providing process.";
      default:
        break;
    }
  };

  static getColor = (isRejected, isAuthorized) => {
    const status = this.getStatus(isRejected, isAuthorized);

    switch (status) {
      case this.STATUS.UNAUTHORIZED:
        return "bg-gray-500";
      case this.STATUS.PENDING:
        return "bg-blue-500";
      case this.STATUS.AUTHORIZED:
        return "bg-green-500";
      case this.STATUS.REAUTHORIZED:
        return "bg-orange-500";
      case this.STATUS.REJECTED:
        return "bg-red-500";
      default:
        break;
    }
  };
}
