import Parse from "parse";
import Modal from "./Modal";
import { useState } from "react";
import Button from "./form/Button";
import { useFormik } from "formik";
import Utils from "../utils/Utils";
import { object, string } from "yup";
import FormInput from "./form/FormInput";
import FormSelect from "./form/FormSelect";
import { findOption } from "../utils/Forms";
import useToaster from "../hooks/useToaster";
import FormTextarea from "./form/FormTextarea";

const AddNewMessageTemplateModal = ({ isOpen, toggleModal, emitTemplate }) => {
  const toaster = useToaster();
  const [isLoading, setLoading] = useState(false);
  const [submitTemplateSuccess, setSubmitTemplateSucces] = useState(false);

  const validationSchema = object().shape({
    title_el: string().required("Title is required"),
    title_en: string().required("Title is required"),
    message_el: string().required("Message is required"),
    message_en: string().required("Message is required"),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      type: "",
      title_el: "",
      title_en: "",
      message_el: "",
      message_en: "",
    },
  });

  const typeOptions = [
    { value: "INFO", label: "INFO" },
    { value: "REMINDER", label: "REMINDER" },
    { value: "WARNING", label: "WARNING" },
  ];
  const typeDfaultOption = !Utils.isNull(formik.values.type)
    ? findOption(typeOptions, formik.values.type)
    : null;

  const setValue = (field, value) => {
    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  };

  const handleCancel = () => {
    Object.keys(formik.values).forEach((key) => formik.setFieldValue(key, ""));
    toggleModal(!isOpen);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (formik.isValid) {
        const MessageTemplate = await new Parse.Object("UserMessageTemplates")
          .set("type", formik.values.type)
          .set("title_el", formik.values.title_el)
          .set("title_en", formik.values.title_en)
          .set("message_el", formik.values.message_el)
          .set("message_en", formik.values.message_en)
          .save(null, { useMasterKey: true });
        setSubmitTemplateSucces(true);
        emitTemplate(MessageTemplate);
        setTimeout(() => {
          Object.keys(formik.values).forEach((key) => formik.setFieldValue(key, ""));
          toggleModal(!isOpen);
        }, 1500);
      }
    } catch (e) {
      setSubmitTemplateSucces(false);
      setLoading(false);
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  return (
    <Modal
      open={isOpen}
      title='Add Message Template'
      classes={"w-4/5 md:w-1/2 xl:w-1/3"}
      handleToggle={() => toggleModal(!isOpen)}
    >
      <div className='flex flex-col gap-2'>
        <div>
          <FormSelect
            label='Type'
            placeholder='Type'
            isClearable={false}
            options={typeOptions}
            value={typeDfaultOption}
            handleChange={(value) => setValue("type", value.value)}
          />
        </div>
        <div>
          <FormInput
            type='text'
            label='Title EL'
            placeholder='Title EL'
            value={formik.values.title_el}
            errors={formik.touched.title_el && formik.errors.title_el}
            handleChange={(e) => setValue("title_el", e.target.value)}
          />
        </div>
        <div>
          <FormInput
            type='text'
            label='Title EN'
            placeholder='Title EN'
            value={formik.values.title_en}
            errors={formik.touched.title_en && formik.errors.title_en}
            handleChange={(e) => setValue("title_en", e.target.value)}
          />
        </div>
        <div>
          <FormTextarea
            rows='5'
            type='text'
            label='Message EL'
            placeholder='Message EL'
            value={formik.values.message_el}
            handleChange={(value) => setValue("message_el", value)}
            errors={formik.touched.message_el && formik.errors.message_el}
          />
        </div>
        <div>
          <FormTextarea
            rows='5'
            type='text'
            label='Message EN'
            placeholder='Message EN'
            value={formik.values.message_en}
            handleChange={(value) => setValue("message_en", value)}
            errors={formik.touched.message_en && formik.errors.message_en}
          />
        </div>
        <div className='grid grid-cols-2 gap-2 mt-4'>
          <Button classes='bg-gray-200 py-2' disabled={isLoading} handleClick={handleCancel}>
            <span>Cancel</span>
          </Button>
          <Button
            isLoading={isLoading}
            handleClick={handleSubmit}
            classes={`bg-blue-500 py-2`}
            success={submitTemplateSuccess}
            disabled={isLoading || submitTemplateSuccess}
          >
            <span className='text-white'>Add</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddNewMessageTemplateModal;
