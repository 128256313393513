import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import { dateFormatter } from "../../../utils/Dates";
import { getViewportWidth } from "../../../utils/Viewport";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { adminLogsSerializer } from "../../../models/serializers";
import { SortingState, PagingState, CustomPaging } from "@devexpress/dx-react-grid";
import { Grid, Table, PagingPanel, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";

const UserRentingsTable = ({
  logs,
  sorting,
  isLoading,
  pagination,
  totalElements,
  handleSorting,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const windowSize = getViewportWidth();
  const sortableColumns = ["action", "createdAt"];

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props} className='sortable-column-label'>
          {props.children}
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const resolveUsername = (data) => {
    return (
      <Link className='link' to={`/users/${data?.attributes?.user?.id}`}>
        {data?.attributes?.user?.attributes?.username}
      </Link>
    );
  };

  const resolveColumns = () => {
    switch (true) {
      case windowSize === "MOBILE":
        return [
          {
            name: "user",
            title: "User",
            getCellValue: resolveUsername,
          },
        ];
      default:
        return [
          {
            name: "user",
            title: "User",
            getCellValue: resolveUsername,
          },
          {
            name: "action",
            title: "Action",
            getCellValue: (data) => data.attributes.action,
          },
          {
            name: "extra",
            title: "Extra",
            getCellValue: (data) => data.attributes.extra,
          },
          {
            name: "createdAt",
            title: "Created At",
            getCellValue: (data) => dateFormatter(data.attributes.createdAt, true),
          },
        ];
    }
  };

  const columns = resolveColumns();

  const cellComponent = (props) => {
    if (windowSize === "MOBILE") {
      const log = adminLogsSerializer(props.row);

      return (
        <Table.Cell {...props} padding='none'>
          <div className='relative py-2'>
            <div>
              <span>{resolveUsername(props.row)}</span>
              <FontAwesomeIcon icon={faMinus} size='xs' className='px-2' />
              <span>{log.createdAt}</span>
            </div>
            <div className='mt-2'>
              <span>{log.extra}</span>
            </div>
          </div>
        </Table.Cell>
      );
    } else {
      return <Table.Cell {...props} />;
    }
  };

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <div className='z-10'>
        <div className='fixed-table'>
          <Grid rows={logs} columns={columns} getRowId={(row) => row.id}>
            <SortingState sorting={sorting} onSortingChange={handleSorting} />
            <PagingState
              currentPage={pagination.page}
              pageSize={pagination.size}
              onCurrentPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
            <CustomPaging totalCount={totalElements} />
            <Table
              columnExtensions={[
                {
                  columnName: "user",
                  wordWrapEnabled: true,
                  width: "auto",
                },
                {
                  columnName: "action",
                  wordWrapEnabled: true,
                  width: "auto",
                },
                { columnName: "extra", wordWrapEnabled: true, width: "auto" },
                {
                  columnName: "createdAt",
                  wordWrapEnabled: true,
                  width: "150",
                  align: "center",
                },
              ]}
              cellComponent={cellComponent}
            />
            <TableHeaderRow
              sortLabelComponent={TableHeaderRowSortLabelComponent}
              showSortingControls={true}
            />
            <PagingPanel pageSizes={[10, 25, 50, 100]} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default UserRentingsTable;
