import dayjs from "dayjs";
import Loader from "../../Loader";
import { useFormik } from "formik";
import { object, number } from "yup";
import { Tooltip } from "@mui/material";
import Utils from "../../../utils/Utils";
import { isEqual, isNumber } from "lodash";
import { useEffect, useState } from "react";
import FormInput from "../../form/FormInput";
import FormSelect from "../../form/FormSelect";
import { findOption } from "../../../utils/Forms";
import FormDatepicker from "../../form/FormDatepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEuro,
  faTags,
  faXmark,
  faCheck,
  faPencil,
  faPercentage,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";

const CampaignCardView = ({ campaign, isLoading, handleSubmit, discountsGenerated }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const isPast = dayjs(campaign.attributes.end).isBefore(dayjs());
  const isAfter = dayjs(dayjs()).isAfter(campaign.attributes.start);

  const validationSchema = object().shape({
    discountAmount: number().required("Required").typeError("Number!"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      discountType: campaign.attributes.discountType,
      discountAmount: campaign.attributes.discountAmount,
      start: campaign.attributes.start,
      end: campaign.attributes.end,
    },
  });

  const discountTypeOptions = [
    { value: "FIX", label: "FIX" },
    { value: "PERCENTAGE", label: "PERCENTAGE" },
  ];
  const discountTypeDfaultOption = !Utils.isNull(formik.values.discountType)
    ? findOption(discountTypeOptions, formik.values.discountType)
    : null;

  const getAmountSymbol = () => {
    if (campaign.attributes.discountType === "FIX") {
      return <FontAwesomeIcon icon={faEuro} size='sm' />;
    } else {
      return <FontAwesomeIcon icon={faPercentage} />;
    }
  };

  const handleToggleEdit = () => {
    if (isEditing) {
      formik.setFieldValue("end", formik.initialValues.end);
      formik.setFieldValue("start", formik.initialValues.start);
      formik.setFieldValue("discountType", formik.initialValues.discountType);
      formik.setFieldValue("discountAmount", formik.initialValues.discountAmount);
    }
    setIsEditing(!isEditing);
  };

  const submitForm = () => {
    handleSubmit(formik);
    setIsEditing(false);
  };

  useEffect(() => {
    if (!isEqual(formik.values, formik.initialValues) && formik.isValid) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [formik.values]);

  return (
    <>
      <Loader isLoading={isLoading} />
      {!isPast && (
        <div className='edit'>
          <Tooltip title={isEditing ? "Discard" : "Edit"} placement='top'>
            <FontAwesomeIcon
              className='w-4 h-4'
              icon={isEditing ? faXmark : faPencil}
              onClick={handleToggleEdit}
            />
          </Tooltip>
          {canSubmit && (
            <Tooltip title='Submit' placement='top'>
              <FontAwesomeIcon icon={faCheck} className='w-4 h-4' onClick={submitForm} />
            </Tooltip>
          )}
        </div>
      )}
      <div className='card z-10 relative' key={campaign.id}>
        <div className='title'>
          <span className='text-lg md:text-2xl font-bold'>{campaign.attributes.code}</span>
          <div className='flex items-center gap-1'>
            <FontAwesomeIcon icon={faTags} className='-rotate-90' size='xl' />
            {isEditing ? (
              <div className='flex items-start gap-1'>
                <div className='w-24 sm:w-32'>
                  <FormSelect
                    placeholder='Type'
                    disabled={isAfter}
                    isClearable={false}
                    options={discountTypeOptions}
                    value={discountTypeDfaultOption}
                    handleChange={(value) => formik.setFieldValue("discountType", value.value)}
                  />
                </div>
                <div className='w-14'>
                  <FormInput
                    type='text'
                    disabled={isAfter}
                    placeholder='Amount'
                    value={formik.values.discountAmount}
                    errors={formik.touched.discountAmount && formik.errors.discountAmount}
                    handleChange={(e) => {
                      formik.setFieldTouched("discountAmount");
                      formik.setFieldValue(
                        "discountAmount",
                        isNumber(e.target.value) ? Number(e.target.value) : e.target.value
                      );
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className='flex flex-nowrap items-center gap-0.5 text-base md:text-xl font-medium'>
                {campaign.attributes.discountAmount} {getAmountSymbol()}
              </div>
            )}
          </div>
        </div>
        <div className='flex items-center justify-center gap-2 mb-4 text-center'>
          {!!discountsGenerated || discountsGenerated === 0 ? (
            <span className='text-xl font-bold'>{discountsGenerated}</span>
          ) : (
            <div className='w-6 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}{" "}
          <span className='text-lg font-medium'>discounts generated</span>
        </div>
        <div className='dates'>
          <div className='date'>
            <div className='title'>
              <span className=''>Starts:</span>
            </div>

            {isEditing ? (
              <div className='w-34 sm:w-40 md:w-52'>
                <FormDatepicker
                  withTime={true}
                  disabled={isAfter}
                  isClearable={false}
                  value={dayjs(formik.values.start)}
                  handleChange={(value) => formik.setFieldValue("start", value)}
                />
              </div>
            ) : (
              <div className='date-wrapper'>
                <div className='dmy'>
                  <span className='flex items-center gap-3'>
                    <FontAwesomeIcon icon={faCalendarDay} />{" "}
                    {dayjs(campaign.attributes.start).format("DD MMM")}{" "}
                    {dayjs(campaign.attributes.start).format("YYYY")}
                  </span>
                </div>
                <div className='time'>
                  <span>{dayjs(campaign.attributes.start).format("HH:mm")}</span>
                </div>
              </div>
            )}
          </div>
          <div className='date'>
            <div className='title'>
              <span className=''>Ends:</span>
            </div>
            {isEditing ? (
              <div className='w-34 sm:w-40 md:w-52'>
                <FormDatepicker
                  withTime={true}
                  isClearable={false}
                  value={dayjs(formik.values.end)}
                  handleChange={(value) => formik.setFieldValue("end", value)}
                />
              </div>
            ) : (
              <div className='date-wrapper'>
                <div className='dmy'>
                  <span className='flex items-center gap-3'>
                    <FontAwesomeIcon icon={faCalendarDay} />{" "}
                    {dayjs(campaign.attributes.end).format("DD MMM")}{" "}
                    {dayjs(campaign.attributes.end).format("YYYY")}
                  </span>
                </div>
                <div className='time'>
                  <span>{dayjs(campaign.attributes.end).format("HH:mm")}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignCardView;
