import dayjs from "dayjs";
import { useState } from "react";
import AddCampaignModal from "../components/AddCampaignModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import CampaignCardState from "../../../components/with-state/state/CampaignCardState";

const CampaignsSettingsPageView = ({ campaigns, emitCampaign }) => {
  const [isAddCampaignModalOpen, toggleAddCampaignModal] = useState(false);
  const past = campaigns.filter((c) => dayjs(c.attributes.end).isBefore(dayjs()));
  const upcoming = campaigns.filter((c) => dayjs(c.attributes.start).isAfter(dayjs()));
  const current = campaigns.filter(
    (c) => dayjs(c.attributes.start).isBefore(dayjs()) && dayjs(c.attributes.end).isAfter(dayjs())
  );

  return (
    <>
      <div className='title flex items-center justify-between'>
        <span>Campaign Settings</span>
        <div
          className='flex items-center gap-1 text-text-main cursor-pointer'
          onClick={() => toggleAddCampaignModal(true)}
        >
          <FontAwesomeIcon icon={faSquarePlus} />
          <span className='text-sm sm:text-base'>Add New</span>
        </div>
      </div>
      <div className='settings-content'>
        <div className='campaign-section'>
          <div className='title'>
            <span>Current</span>
          </div>
          <div className='cards'>
            {current.length > 0 ? (
              current.map((c) => {
                return <CampaignCardState key={c.id} campaign={c} />;
              })
            ) : (
              <span className='not-found'>No campaigns</span>
            )}
          </div>
        </div>
        <div className='campaign-section'>
          <div className='title'>
            <span>Upcoming</span>
          </div>
          <div className='cards'>
            {upcoming.length > 0 ? (
              upcoming.map((c) => {
                return <CampaignCardState key={c.id} campaign={c} />;
              })
            ) : (
              <span className='not-found'>No campaigns</span>
            )}
          </div>
        </div>
        <div className='campaign-section'>
          <div className='title'>
            <span>Past</span>
          </div>
          <div className='cards'>
            {past.length > 0 ? (
              past.map((c) => {
                return <CampaignCardState key={c.id} campaign={c} />;
              })
            ) : (
              <span className='not-found'>No campaigns</span>
            )}
          </div>
        </div>
      </div>
      <AddCampaignModal
        emitCampaign={emitCampaign}
        isOpen={isAddCampaignModalOpen}
        toggleModal={toggleAddCampaignModal}
      />
    </>
  );
};

export default CampaignsSettingsPageView;
