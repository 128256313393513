import Parse from "parse";
import Utils from "../utils/Utils";
import useUser from "../hooks/useUser";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ACTIONS as SESSION_ACTIONS } from "../store/actions/session";

const Authorizer = ({ children, access }) => {
  const userHook = useUser();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = userHook.getCurrentSession();
  const [isAuthorized, setAuthorized] = useState(false);

  const getAuthorization = () => {
    const userRoles = userHook.getUserRoles();

    const permission = access
      .flatMap((i) =>
        userRoles?.flatMap((j) => {
          if (i.toUpperCase() === j.toUpperCase()) return j.toUpperCase();
        })
      )
      .filter((item) => item);

    if (Utils.isEmpty(permission)) {
      navigate("/not-permitted");
    }
  };

  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        let currentUser = Parse.User.current();
        let currentSession = await Parse.Session.current();

        setAuthorized(
          !Utils.isNull(currentUser) && !Utils.isNull(currentSession) && !Utils.isNull(session)
        );

        if (Utils.isNull(currentUser) || Utils.isNull(currentSession) || Utils.isNull(session)) {
          await Parse.User.logOut();
          dispatch(SESSION_ACTIONS.userLogout());
          navigate("/login");
        }
      } catch (e) {
        await Parse.User.logOut();
        dispatch(SESSION_ACTIONS.userLogout());
        navigate("/login");
      }
    };

    checkAuthorization();
  }, [location]);

  useEffect(() => {
    if (access) {
      getAuthorization();
    }
  }, []);

  return <>{isAuthorized && children}</>;
};

export default Authorizer;
