export default class Car {
  static FUELTYPES = {
    PETROL: "PETROL",
    DIESEL: "DIESEL",
    HYBRID: "HYBRID",
  };

  static getFuelColor = (fuel) => {
    switch (true) {
      case fuel < 25:
        return "bg-red-500";
      case fuel >= 25 && fuel < 50:
        return "bg-orange-500";
      case fuel >= 50 && fuel < 75:
        return "bg-yellow-500";
      case fuel >= 75:
        return "bg-green-600";
      default:
        return "";
    }
  };

  static getFuelColorMUI = (fuel) => {
    switch (true) {
      case fuel < 25:
        return "error";
      case fuel >= 25 && fuel < 75:
        return "warning";
      case fuel >= 75:
        return "success";
      default:
        return "primary";
    }
  };
}
