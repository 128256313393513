import { useState } from "react";
import User from "../../../models/User";
import { Tooltip } from "@mui/material";
import useUser from "../../../hooks/useUser";
import Modal from "../../../components/Modal";
import Loader from "../../../components/Loader";
import Toggler from "../../../components/Toggler";
import Button from "../../../components/form/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Card from "../../../components/stripe/Card";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

const UserAuthorizationControls = ({
  user,
  isLoading,
  paymentMethod,
  handleToggleLocked,
  handleToggleEnabled,
  handleUserAuthorization,
  handleToggleEmailVerified,
  handleTogglePhoneVerified,
}) => {
  const userHook = useUser();
  const userRoles = userHook.getUserRoles();
  const [isModalOpen, toggleModal] = useState(false);
  const [authAction, setAuthAction] = useState(null);
  const adminAccess = userRoles?.includes(User.ROLES.ADMIN);
  const allowAuthControls = user.emailVerified || user.phoneVerified;
  const userStatus = User.getStatus(user.isRejected, user.isAuthorized);

  const showAuthControls = adminAccess;

  const authorizeUser = (action) => {
    setAuthAction(action);
    toggleModal(true);
  };

  const handleCancel = () => {
    setAuthAction(null);
    toggleModal(false);
  };

  const handleSubmit = () => {
    handleUserAuthorization(authAction, user.id);
    setAuthAction(null);
    toggleModal(false);
  };

  return (
    <div>
      <div className='mb-2'>
        <span className='text-lg font-bold'>User Status</span>
      </div>
      <div className='relative user-row-controls'>
        <Loader isLoading={isLoading} />
        {showAuthControls && (
          <div className='mb-4'>
            {!allowAuthControls && (
              <span className='text-xs text-orange-700'>
                Controls are disabled as the user hasn't verified email or phone yet
              </span>
            )}
            <div
              className={`authorization-controls ${allowAuthControls ? "allowed" : "not-allowed"}`}
            >
              <div
                className={`${userStatus === User.STATUS.AUTHORIZED ? "active" : ""} button`}
                onClick={() => (allowAuthControls ? authorizeUser(User.ACTIONS.AUTHORIZE) : null)}
              >
                {userStatus === User.STATUS.AUTHORIZED ? (
                  <span>Authorized</span>
                ) : (
                  <span>Authorize</span>
                )}
              </div>
              <div
                className={`${userStatus === User.STATUS.REAUTHORIZED ? "active" : ""} button`}
                onClick={() => (allowAuthControls ? authorizeUser(User.ACTIONS.REAUTHORIZE) : null)}
              >
                {userStatus === User.STATUS.REAUTHORIZED ? (
                  <span>Reauthorized</span>
                ) : (
                  <span>Reauthorize</span>
                )}
              </div>
              <div
                className={`${userStatus === User.STATUS.REJECTED ? "active" : ""} button`}
                onClick={() => (allowAuthControls ? authorizeUser(User.ACTIONS.REJECT) : null)}
              >
                {userStatus === User.STATUS.REJECTED ? <span>Rejected</span> : <span>Reject</span>}
              </div>
            </div>
          </div>
        )}
        <div className='row'>
          <span className='relative'>
            User Enabled:
            <Tooltip
              title='Indicates if the user has disabled their account. Enabled turns false when the user requests account deletion.'
              placement='top'
            >
              <FontAwesomeIcon
                size='xs'
                icon={faQuestionCircle}
                className='absolute text-blue-500 cursor-pointer'
              />
            </Tooltip>
          </span>
          <Toggler
            toggle={user.isEnabled}
            handleToggle={(toggle) => handleToggleEnabled(toggle, user.id)}
            disabled={isLoading || !adminAccess}
          />
        </div>
        <div className='row'>
          <span className='relative'>
            User Locked:
            <Tooltip
              title='Indicates if the user has gotten their account locked for some reason. Locked could turn true when the user did not upload images of the car upon ending a ride.'
              placement='top'
            >
              <FontAwesomeIcon
                size='xs'
                icon={faQuestionCircle}
                className='absolute text-blue-500 cursor-pointer'
              />
            </Tooltip>
          </span>
          <Toggler
            toggle={user.isLocked}
            handleToggle={(toggle) => handleToggleLocked(toggle, user.id)}
            disabled={isLoading || !adminAccess}
          />
        </div>
        <div className='row'>
          <span className='relative'>
            Verified Email:
            <Tooltip title='Indicates if the user has verified their email.' placement='top'>
              <FontAwesomeIcon
                size='xs'
                icon={faQuestionCircle}
                className='absolute text-blue-500 cursor-pointer'
              />
            </Tooltip>
          </span>
          <Toggler
            toggle={user.emailVerified}
            handleToggle={(toggle) => handleToggleEmailVerified(toggle, user.id)}
            disabled={isLoading || !adminAccess}
          />
        </div>
        <div className='row'>
          <span className='relative'>
            Verified Phone:
            <Tooltip title='Indicates if the user has verified their phone.' placement='top'>
              <FontAwesomeIcon
                size='xs'
                icon={faQuestionCircle}
                className='absolute text-blue-500 cursor-pointer'
              />
            </Tooltip>
          </span>
          <Toggler
            toggle={user.phoneVerified}
            handleToggle={(toggle) => handleTogglePhoneVerified(toggle, user.id)}
            disabled={isLoading || !adminAccess}
          />
        </div>
        <span>Payment Method:</span>
        <span>
          {!!paymentMethod ? (
            <Tooltip title='User has a default payment method set' placement='top'>
              <FontAwesomeIcon
                size='lg'
                icon={faCheckCircle}
                className='ml-2 text-green-500 cursor-pointer'
              />
            </Tooltip>
          ) : (
            <Tooltip title='No payment method set' placement='top'>
              <FontAwesomeIcon size='lg' icon={faMinus} className='ml-2 cursor-pointer' />
            </Tooltip>
          )}
        </span>
      </div>
      <Modal title={"REVIEW"} open={isModalOpen} handleToggle={toggleModal}>
        <div className='bg-bg-input rounded'>
          <div className='flex flex-col mb-8'>
            <span>You are about to perform a User Authorization Action.</span>
            <span>Review your action below and make sure you got everything right.</span>
          </div>
          <div className='flex justify-center gap-1 text-xl mb-8'>
            <span>Action:</span>
            <span className='font-bold'>{authAction}</span>
          </div>
        </div>
        <div className='grid grid-cols-2 gap-2 mt-4'>
          <Button classes='bg-gray-200 py-2 text-gray-700' handleClick={handleCancel}>
            <span>Cancel</span>
          </Button>
          <Button classes='bg-blue-500 py-2' handleClick={handleSubmit}>
            <span className='text-white'>Submit</span>
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default UserAuthorizationControls;
