import dayjs from "dayjs";
import Loader from "../../Loader";
import { useFormik } from "formik";
import Toggler from "../../Toggler";
import Button from "../../form/Button";
import { Tooltip } from "@mui/material";
import Utils from "../../../utils/Utils";
import { isEqual, isNumber } from "lodash";
import { useEffect, useState } from "react";
import { object, number, string } from "yup";
import FormInput from "../../form/FormInput";
import FormSelect from "../../form/FormSelect";
import { findOption } from "../../../utils/Forms";
import FormDatepicker from "../../form/FormDatepicker";
import FormTextarea from "../../../components/form/FormTextarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEuro,
  faXmark,
  faPencil,
  faPercentage,
  faChampagneGlasses,
} from "@fortawesome/free-solid-svg-icons";

const HappyHourCardView = ({ happyHour, isLoading, handleToggle, handleSubmit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const validationSchema = object().shape({
    text: string().required("Required"),
    discount: number().required("Required").typeError("Number!"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      type: happyHour.attributes.type,
      discount: happyHour.attributes.discount,
      startsAt: happyHour.attributes.startsAt,
      endsAt: happyHour.attributes.endsAt,
      text: happyHour.attributes.text,
    },
  });

  const discountTypeOptions = [
    { value: "FIX", label: "FIX" },
    { value: "PERCENTAGE", label: "PERCENTAGE" },
  ];
  const discountTypeDfaultOption = !Utils.isNull(formik.values.type)
    ? findOption(discountTypeOptions, formik.values.type)
    : null;

  const getAmountSymbol = () => {
    if (happyHour.attributes.type === "FIX") {
      return <FontAwesomeIcon icon={faEuro} size='sm' />;
    } else {
      return <FontAwesomeIcon icon={faPercentage} />;
    }
  };

  const handleToggleEdit = () => {
    if (isEditing) {
      formik.setFieldValue("text", formik.initialValues.text);
      formik.setFieldValue("endsAt", formik.initialValues.endsAt);
      formik.setFieldValue("startsAt", formik.initialValues.startsAt);
      formik.setFieldValue("type", formik.initialValues.type);
      formik.setFieldValue("discount", formik.initialValues.discount);
    }
    setIsEditing(!isEditing);
  };

  const submitForm = () => {
    handleSubmit(formik);
    setIsEditing(false);
  };

  useEffect(() => {
    if (!isEqual(formik.values, formik.initialValues) && formik.isValid) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [formik.values]);

  return (
    <div className='card'>
      <Loader isLoading={isLoading} />
      <div className='mb-4 md:mb-6 border-b border-border-table-body pb-4 md:pb-6'>
        <div className='flex items-center justify-between'>
          <div className='title'>
            <span className='text-xl md:text-3xl flex items-center gap-2 sm:gap-4'>
              <FontAwesomeIcon icon={faChampagneGlasses} />
              Happy Hour
            </span>

            {!isEditing && (
              <div className='hidden lg:flex items-center gap-4'>
                <span className='text-lg md:text-xl'>
                  At:{" "}
                  <span className='font-bold'>
                    {dayjs(happyHour.attributes.startsAt).format("HH:mm")}
                  </span>
                </span>
                <span className='text-lg md:text-xl'>
                  Until:{" "}
                  <span className='font-bold'>
                    {dayjs(happyHour.attributes.endsAt).format("HH:mm")}
                  </span>
                </span>
              </div>
            )}
          </div>
          <div className='flex items-center gap-4'>
            <Tooltip title='Enable/Disable Happy Hour' placement='top'>
              <Toggler toggle={happyHour.attributes.isEnabled} handleToggle={handleToggle} />
            </Tooltip>
            <div className='text-text-main cursor-pointer' onClick={handleToggleEdit}>
              <FontAwesomeIcon icon={isEditing ? faXmark : faPencil} size='lg' />
            </div>
          </div>
        </div>
        {!isEditing && (
          <div className='flex lg:hidden items-center gap-2 sm:gap-4 text-text-main mt-2'>
            <span className='text-lg md:text-xl'>
              At:{" "}
              <span className='font-bold'>
                {dayjs(happyHour.attributes.startsAt).format("HH:mm")}
              </span>
            </span>
            <span className='text-lg md:text-xl'>
              Until:{" "}
              <span className='font-bold'>
                {dayjs(happyHour.attributes.endsAt).format("HH:mm")}
              </span>
            </span>
          </div>
        )}
        {isEditing && (
          <div className='flex items-center gap-2 mt-2'>
            <FormDatepicker
              label='Starts At'
              onlyTime={true}
              isClearable={false}
              value={dayjs(formik.values.startsAt)}
              handleChange={(value) => formik.setFieldValue("startsAt", value)}
            />
            <FormDatepicker
              label='Ends At'
              onlyTime={true}
              isClearable={false}
              value={dayjs(formik.values.endsAt)}
              handleChange={(value) => formik.setFieldValue("endsAt", value)}
            />
          </div>
        )}
      </div>
      <div
        className={`flex items-center justify-between ${
          isEditing ? "flex-col md:flex-row gap-1 md:gap-4" : "gap-4"
        }`}
      >
        <div>
          <div>
            <span className='text-lg lg:text-xl leading-1 font-semibold text-text-main'>
              Message to users:
            </span>
          </div>
          <div>
            {isEditing ? (
              <div className='w-40 md:w-48 lg:w-60 mt-2'>
                <FormTextarea
                  rows={2}
                  value={formik.values.text}
                  errors={formik.touched.text && formik.errors.text}
                  handleChange={(value) => {
                    formik.setFieldTouched("text");
                    formik.setFieldValue("text", value);
                  }}
                />
              </div>
            ) : (
              <span className='text-lg text-text-main'>{happyHour.attributes.text}</span>
            )}
          </div>
        </div>
        {isEditing ? (
          <div className='flex items-center gap-1'>
            <div className='w-48 md:w-36 lg:w-44'>
              <FormSelect
                label='Type'
                placeholder='Type'
                isClearable={false}
                options={discountTypeOptions}
                value={discountTypeDfaultOption}
                handleChange={(value) => formik.setFieldValue("type", value.value)}
              />
            </div>
            <div className='w-20 md:w-16'>
              <FormInput
                type='text'
                label='Amount'
                placeholder='Amount'
                value={formik.values.discount}
                errors={formik.touched.discount && formik.errors.discount}
                handleChange={(e) => {
                  formik.setFieldTouched("discount");
                  formik.setFieldValue(
                    "discount",
                    isNumber(e.target.value) ? Number(e.target.value) : e.target.value
                  );
                }}
              />
            </div>
          </div>
        ) : (
          <div className='flex items-center gap-2 text-lg md:text-2xl text-text-main'>
            <span className='font-semibold'>Discount:</span>
            <div className='flex items-center gap-1'>
              <span>{happyHour.attributes.discount}</span>
              <span>{getAmountSymbol()}</span>
            </div>
          </div>
        )}
      </div>
      {canSubmit && (
        <div className='flex justify-end mt-2'>
          <Button
            handleClick={submitForm}
            disabled={isLoading || !formik.isValid}
            classes='bg-bg-btn-primary py-1 px-2'
          >
            <span className='text-white'>Save</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default HappyHourCardView;
