import Parse from "parse";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import useToaster from "../../../hooks/useToaster";
import CampaignsSettingsPageView from "../view/CampaignsSettingsPageView";

const CampaignsSettingsPageState = ({}) => {
  const toaster = useToaster();
  const [isLoading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState(null);

  const getCampaigns = async () => {
    setLoading(true);
    try {
      const Campaigns = await new Parse.Query("Campaign")
        .descending("createdAt")
        .find({ useMasterKey: true });
      setLoading(false);
      setCampaigns(Campaigns);
    } catch (e) {
      setLoading(false);
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleAddCampaign = (campaign) => {
    setCampaigns((prev) => [...prev, campaign]);
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <>
      {!!campaigns ? (
        <>
          <Loader isLoading={isLoading} />
          <CampaignsSettingsPageView campaigns={campaigns} emitCampaign={handleAddCampaign} />
        </>
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default CampaignsSettingsPageState;
